import { useEffect } from 'react';
import { RootPagesStyledDiv, RootPagesStyledHeader2 } from '../../Styles/GeneralStyles';
import { LegalStatementTextStyledDiv, LegalStatementWrapperStyledDiv } from '../../Styles/LegalStatementStyles';
import { PrivacyStatement } from './PrivacyStaments';

function Privacy() {
  useEffect(() => {
    document.title = 'BySárago - Privacidad';
  }, []);

  return (
    <RootPagesStyledDiv>
      <RootPagesStyledHeader2>Términos y Condiciones</RootPagesStyledHeader2>
      <LegalStatementWrapperStyledDiv>
        <LegalStatementTextStyledDiv>{PrivacyStatement()}</LegalStatementTextStyledDiv>
      </LegalStatementWrapperStyledDiv>
    </RootPagesStyledDiv>
  );
}

export { Privacy };
