import './App.css';
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Navigate, Route } from 'react-router-dom';

import { Home } from './Components/Home';
import { Shop } from './Components/Shop';
import { Outlet } from './Components/Outlet';
import { EarringPage } from './Components/EarringPage';

import { NavBar } from './Components/Navbar';
import { MainPageStyledDiv } from './Styles/GeneralStyles';
import { ShoppingCart } from './Components/ShoppingCart';
import { CheckoutPage } from './Components/CheckoutPage';
import { CheckoutConfirmation } from './Components/CheckoutConfirmation';
import { Privacy } from './Components/LegalStatements/Privacy';
import { TermsAndConditions } from './Components/LegalStatements/TermsAndConditions';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<NavBar />}>
      <Route index element={<Home />} />
      <Route path="shop" element={<Shop />} />
      <Route path="outlet" element={<Outlet />} />
      <Route path="cart" element={<ShoppingCart />} />
      <Route path="checkout" element={<CheckoutPage />} />
      <Route path="checkout_confirmation" element={<CheckoutConfirmation />} />
      <Route path="/earrings/:id" element={<EarringPage />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/terms" element={<TermsAndConditions />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Route>,
  ),
);

export default function App() {
  return (
    <MainPageStyledDiv>
      <RouterProvider router={router} />
    </MainPageStyledDiv>
  );
}
