import { MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Earring, EarringMetalColor, EarringSelection } from '../Models/EarringDataModel';
import {
  CIRCLE_COLOR_PICKER_CIRCLE_SIZE,
  CIRCLE_COLOR_PICKER_CIRCLE_SPACING,
  IMAGES_EARRINGS_PATH,
} from '../Utils/Constants';
import { CalculateImageHeightsForGallery } from '../Utils/ScreenSize';
import {
  AddedToCartPopOverStyle,
  EarringPageAddToShoppingCartButtonStyledButton,
  EarringPageAddToShoppingCartWrapperStyledDiv,
  EarringPageDescriptionStyledDiv,
  EarringPageImageGalleryStyledDiv,
  EarringPageNameStyledDiv,
  EarringPageTextSectionTitleStyledDiv,
  EarringPageTextSectionWrapperStyledDiv,
  EarringProductDetailPageInformationStyledDiv,
  EarringProductDetailPageMainWrapperStyledDiv,
  EarringProductDetailPageWrapperStyledDiv,
} from '../Styles/EarringPageStyles';
import { addItemToShoppingCartContent, countEarringAndColorElementsInShoppingCart } from '../Utils/ShoppingCart';
import { buildColorPicker } from '../Utils/BuildColorPicker';
import { getEarring } from '../API/API';
import { LoadingSpinner } from './LoadingSpinner';
import { sendGAnalyticsEvent } from '../GAnalytics/Main';

type EarringMetadataJsonFile = {
  images: string[];
  thumbnails: string[];
};

function fetchMetadataJsonInformation(earringId: string): Promise<EarringMetadataJsonFile> {
  const url: string = `${IMAGES_EARRINGS_PATH}/${earringId}/meta.json`;
  return fetch(url).then(response => {
    return response.json() as Promise<EarringMetadataJsonFile>;
  });
}

function buildColorToImagesDict(collection: string[], earringId: string): { [key: string]: string[] } {
  const result: { [key: string]: string[] } = {};
  for (let i = 0; i < collection.length; i += 1) {
    const elementSplit: string[] = collection[i].split('/');
    const color: string = elementSplit[0];
    const image: string = elementSplit[1];

    if (!result[color]) {
      result[color] = [];
    }
    result[color].push(`${IMAGES_EARRINGS_PATH}/${earringId}/${color}/${image}`);
  }

  return result;
}

function buildImagesUrls(earringId: string): Promise<{ [key: string]: { [key: string]: string[] } }> {
  const rawMetaPromise: Promise<EarringMetadataJsonFile> = fetchMetadataJsonInformation(earringId);
  return rawMetaPromise.then(rawMeta => {
    const imagesToColorUrls = buildColorToImagesDict(rawMeta.images.sort(), earringId);
    const thumbnailsToColorUrls = buildColorToImagesDict(rawMeta.thumbnails.sort(), earringId);
    return { images: imagesToColorUrls, thumbnails: thumbnailsToColorUrls };
  });
}

function buildImagesForGalleryForColor(
  imagesPerColorUrls: { [key: string]: { [key: string]: string[] } },
  color: string,
  heightForImages: number,
): { original: string; thumbnail: string; originalHeight: number }[] {
  const imagesForGallery = [];
  for (let i = 0; i < imagesPerColorUrls.images[color].length; i += 1) {
    imagesForGallery.push({
      original: imagesPerColorUrls.images[color][i],
      thumbnail: imagesPerColorUrls.thumbnails[color][i],
      originalHeight: heightForImages,
    });
  }
  return imagesForGallery;
}

function buildPriceInfo(price: number, discountedPrice: number | null): ReactNode {
  let priceInfo: ReactNode;

  if (!discountedPrice) {
    priceInfo = (
      <EarringPageTextSectionWrapperStyledDiv gridArea="price">
        <EarringPageTextSectionTitleStyledDiv fontSize="1.1rem">
          Precio:&nbsp;<div style={{ fontWeight: '300' }}>{price}€</div>
        </EarringPageTextSectionTitleStyledDiv>
      </EarringPageTextSectionWrapperStyledDiv>
    );
  } else {
    priceInfo = (
      <EarringPageTextSectionWrapperStyledDiv gridArea="price">
        <EarringPageTextSectionTitleStyledDiv>
          Precio:&nbsp;
          <s
            style={{
              textDecorationThickness: 'var(--text_decoration_thickness)',
              textDecorationColor: 'var(--color_charcoal)',
            }}>
            {price}€
          </s>
          &nbsp;&nbsp;{discountedPrice}€
        </EarringPageTextSectionTitleStyledDiv>
      </EarringPageTextSectionWrapperStyledDiv>
    );
  }

  return priceInfo;
}

function buildEarringMetalColorInfo(earringMetalColor: EarringMetalColor): ReactNode {
  let earringMetalColorInfo: ReactNode;
  if (earringMetalColor === EarringMetalColor.Gold) {
    earringMetalColorInfo = (
      <EarringPageTextSectionWrapperStyledDiv gridArea="metal">
        <EarringPageTextSectionTitleStyledDiv>Enganches dorados</EarringPageTextSectionTitleStyledDiv>
      </EarringPageTextSectionWrapperStyledDiv>
    );
  } else if (earringMetalColor === EarringMetalColor.Silver) {
    earringMetalColorInfo = (
      <EarringPageTextSectionWrapperStyledDiv gridArea="metal">
        <EarringPageTextSectionTitleStyledDiv>Enganches plateados</EarringPageTextSectionTitleStyledDiv>
      </EarringPageTextSectionWrapperStyledDiv>
    );
  } else if (earringMetalColor === EarringMetalColor.Both) {
    earringMetalColorInfo = (
      <EarringPageTextSectionWrapperStyledDiv gridArea="metal">
        <EarringPageTextSectionTitleStyledDiv>
          Enganches dorados o plateados, ¡elígelo en el carrito!
        </EarringPageTextSectionTitleStyledDiv>
      </EarringPageTextSectionWrapperStyledDiv>
    );
  } else {
    earringMetalColorInfo = (
      <EarringPageTextSectionWrapperStyledDiv gridArea="metal">
        <EarringPageTextSectionTitleStyledDiv>Enganches no visibles</EarringPageTextSectionTitleStyledDiv>
      </EarringPageTextSectionWrapperStyledDiv>
    );
  }

  return earringMetalColorInfo;
}

function buildEarringDescriptionInfo(description: string): ReactNode {
  return (
    <EarringPageTextSectionWrapperStyledDiv gridArea="info">
      <EarringPageTextSectionTitleStyledDiv>Información adicional:</EarringPageTextSectionTitleStyledDiv>
      <EarringPageDescriptionStyledDiv>{description}</EarringPageDescriptionStyledDiv>
    </EarringPageTextSectionWrapperStyledDiv>
  );
}

function buildEarringColorPickerSection(
  earringId: string,
  selectedColor: string,
  colors: string[],
  colorHexToString: { [key: string]: string },
  setSelectedColor: React.Dispatch<React.SetStateAction<string | undefined>>,
): ReactNode {
  return (
    <EarringPageTextSectionWrapperStyledDiv gridArea="color_picker">
      <EarringPageTextSectionTitleStyledDiv>Colores disponibles:&nbsp;</EarringPageTextSectionTitleStyledDiv>
      {buildColorPicker(
        selectedColor,
        colors,
        color => {
          // We need to not maintain the # in the state so we can access our urls in S3 using
          // the selectedColor item in the state
          setSelectedColor(color.hex.replace('#', ''));
          sendGAnalyticsEvent('earring_color_change', { [earringId]: colorHexToString[color.hex.replace('#', '')] });
        },
        false,
        CIRCLE_COLOR_PICKER_CIRCLE_SIZE,
        CIRCLE_COLOR_PICKER_CIRCLE_SPACING,
      )}
    </EarringPageTextSectionWrapperStyledDiv>
  );
}

function buildEarringColorTextInfo(selectedColor: string, colorHexToString: { [key: string]: string }): ReactNode {
  return (
    <EarringPageTextSectionWrapperStyledDiv gridArea="color_text">
      <EarringPageTextSectionTitleStyledDiv>
        Color seleccionado:&nbsp;
        <div style={{ fontWeight: '300' }}>{colorHexToString[selectedColor]}</div>
      </EarringPageTextSectionTitleStyledDiv>
    </EarringPageTextSectionWrapperStyledDiv>
  );
}

function addEarringToShoppingCart(earring: Earring, selectedColor: string) {
  const earringSelection: EarringSelection = {
    earringID: earring.id,
    color: selectedColor,
    metalColor: earring.metalColor === EarringMetalColor.Both ? EarringMetalColor.Gold : earring.metalColor,
  } as EarringSelection;

  addItemToShoppingCartContent(earringSelection);
}

function buildAddToShoppingCartButton(
  earring: Earring,
  selectedColor: string,
  visibilityOfAddedToCartAlert: boolean,
  handleVisibilityOfAddedToCartAlert: () => void,
): ReactNode {
  const stockForSelectedColor: number = earring.colorsAndStock[selectedColor];
  const elementsInCart: number = countEarringAndColorElementsInShoppingCart(earring.id, selectedColor);

  const addedToCartPopOver = (
    <Popover style={AddedToCartPopOverStyle}>
      <Popover.Body>¡Añadido correctamente!</Popover.Body>
    </Popover>
  );

  let button: ReactNode;
  if (stockForSelectedColor > 0 && elementsInCart < stockForSelectedColor) {
    button = (
      <EarringPageAddToShoppingCartButtonStyledButton
        onClick={() => {
          sendGAnalyticsEvent('added_to_cart', { earring_id: earring.id, selected_color: selectedColor });
          addEarringToShoppingCart(earring, selectedColor);
          handleVisibilityOfAddedToCartAlert();
          // We need to manually fire an event so the NavBar refresh the shopping cart icon
          // https://stackoverflow.com/a/65348883
          window.dispatchEvent(new Event('storage'));
        }}>
        <div style={{ paddingRight: 'min(10px, 2vw)', paddingLeft: 'min(10px, 2vw)' }}>
          Añadir&nbsp;
          <i className="bi bi-cart2" />
        </div>
      </EarringPageAddToShoppingCartButtonStyledButton>
    );
  } else {
    button = (
      <EarringPageAddToShoppingCartButtonStyledButton disabled>Agotado</EarringPageAddToShoppingCartButtonStyledButton>
    );
  }

  return (
    <EarringPageAddToShoppingCartWrapperStyledDiv>
      <OverlayTrigger
        trigger="click"
        show={visibilityOfAddedToCartAlert}
        key="top"
        placement="top"
        overlay={addedToCartPopOver}>
        {button}
      </OverlayTrigger>
    </EarringPageAddToShoppingCartWrapperStyledDiv>
  );
}

function EarringPage() {
  const shouldQueryEarring: MutableRefObject<boolean> = useRef(true);
  const { id } = useParams();
  const [earring, setEarring] = useState<Earring>();
  const [imagesPerColorUrls, setImagesPerColorUrls] = useState<{ [key: string]: { [key: string]: string[] } }>();
  const [selectedColor, setSelectedColor] = useState<string>();
  const [visibilityOfAddedToCartAlert, setVisibilityOfAddedToCartAlert] = useState<boolean>(false);

  useEffect(() => {
    if (shouldQueryEarring.current) {
      const initEarring = async () => {
        getEarring({ earringID: id }).then(x => {
          setEarring(x);
          document.title = `BySárago - ${x.name}`;
        });
      };
      shouldQueryEarring.current = false;
      initEarring();
    }
  }, [id]);

  useEffect(() => {
    if (earring) {
      buildImagesUrls(earring.id).then(result => {
        setImagesPerColorUrls(result);
      });
      setSelectedColor(selectedColor || earring.colorsOrder[0]);
    }
  }, [earring, selectedColor]);

  const heightForImages: number = CalculateImageHeightsForGallery();

  const handleVisibilityOfAddedToCartAlert = () => {
    setVisibilityOfAddedToCartAlert(true);
    setTimeout(() => {
      setVisibilityOfAddedToCartAlert(false);
    }, 1000);
  };

  if (earring && imagesPerColorUrls && selectedColor) {
    const imagesForGallery = buildImagesForGalleryForColor(imagesPerColorUrls, selectedColor, heightForImages);

    const priceInfo: ReactNode = buildPriceInfo(earring.price, earring.discountedPrice);
    const colorPicker = buildEarringColorPickerSection(
      earring.id,
      selectedColor,
      earring.colorsOrder,
      earring.colorHexToString,
      setSelectedColor,
    );
    const colorTextInfo = buildEarringColorTextInfo(selectedColor, earring.colorHexToString);
    const metalColorInfo: ReactNode = buildEarringMetalColorInfo(earring.metalColor);
    const descriptionInfo: ReactNode = buildEarringDescriptionInfo(earring.description);
    const addToShoppingCartButton: ReactNode = buildAddToShoppingCartButton(
      earring,
      selectedColor,
      visibilityOfAddedToCartAlert,
      handleVisibilityOfAddedToCartAlert,
    );

    return (
      <EarringProductDetailPageMainWrapperStyledDiv>
        <EarringProductDetailPageWrapperStyledDiv>
          <EarringPageImageGalleryStyledDiv>
            <ImageGallery items={imagesForGallery} useBrowserFullscreen={false} showPlayButton={false} />
          </EarringPageImageGalleryStyledDiv>
          <EarringProductDetailPageInformationStyledDiv>
            <EarringPageNameStyledDiv>{earring.name}</EarringPageNameStyledDiv>
            {priceInfo}
            {colorPicker}
            {colorTextInfo}
            {metalColorInfo}
            {descriptionInfo}
            {addToShoppingCartButton}
          </EarringProductDetailPageInformationStyledDiv>
        </EarringProductDetailPageWrapperStyledDiv>
      </EarringProductDetailPageMainWrapperStyledDiv>
    );
  }

  return LoadingSpinner({ spinnerSize: '5em', margin: '20px' });
}

export { EarringPage };
