import { ReactNode, useEffect, useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Outlet, Link } from 'react-router-dom'; // ScrollRestoration
import { EarringSelection } from '../Models/EarringDataModel';
import { HeaderStyledNavbar } from '../Styles/GeneralStyles';
import { getShoppingCartContent } from '../Utils/ShoppingCart';
import { Footer } from './Footer';

function shoppingCartIconBadge(shoppingCartContent: EarringSelection[] | null) {
  if (shoppingCartContent && shoppingCartContent.length > 0) {
    return <span>&nbsp;</span>;
  }

  return null;
}

function NavBar() {
  const [shoppingCartBadge, setShoppingCartBadge] = useState<ReactNode>(
    shoppingCartIconBadge(getShoppingCartContent()),
  );

  useEffect(() => {
    function storageEventHandler() {
      setShoppingCartBadge(shoppingCartIconBadge(getShoppingCartContent()));
    }

    window.addEventListener('storage', storageEventHandler);
    return () => {
      window.removeEventListener('storage', storageEventHandler);
    };
  }, []);

  return (
    <>
      <HeaderStyledNavbar
        collapseOnSelect
        className="justify-content-center text-center text-md-start"
        variant="dark"
        expand="sm">
        <Container>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            data-bs-toggle="collapse"
            data-bs-target="#basic-navbar-nav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          />
          <Navbar.Brand as={Link} to="/" className="col-2 col-md-auto">
            BySárago
          </Navbar.Brand>
          <Navbar.Collapse className="order-3 order-sm-1">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/" eventKey="home">
                Inicio
              </Nav.Link>
              <Nav.Link as={Link} to="/shop/" eventKey="shop">
                Tienda
              </Nav.Link>
              <Nav.Link as={Link} to="/outlet/" eventKey="outlet">
                Outlet
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <div className="d-flex flex-row order-1 order-sm-1">
            <Nav.Link
              aria-label="ShopingCartIcon"
              className="text-white shopping-cart-icon-with-badge"
              as={Link}
              to="/cart">
              <>
                <i className="bi bi-cart2 shopping-cart-icon" style={{ fontSize: '1.5em' }} />
                {shoppingCartBadge}
              </>
            </Nav.Link>
          </div>
        </Container>
      </HeaderStyledNavbar>

      <section>
        <Outlet />
        <Footer />
      </section>
      {/* <ScrollRestoration /> */}
    </>
  );
}

export { NavBar };
