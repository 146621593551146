import { Dispatch, MutableRefObject, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { EarringSelection } from '../Models/EarringDataModel';
import { RootPagesStyledDiv, RootPagesStyledHeader2 } from '../Styles/GeneralStyles';
import { LOCAL_STORAGE_KEY_EARRING_GRID_EARRING_CARD_CLICKED_POSITION } from '../Utils/Constants';
import {
  computeTotalPrice,
  getShoppingCartContent,
  isShoppingCartValid,
  saveShoppingCartContent,
} from '../Utils/ShoppingCart';
import {
  CheckoutButtonLoadingSpinnerStyle,
  NotValidShoppingCartPopOverStyle,
  ShoppingCartCheckoutButtonStyledButton,
  ShoppingCartCheckoutWrapperStyledDiv,
  ShoppingCartEmptyCartMessageStyledSpan,
  ShoppingCartGridStyledDiv,
  ShoppingCartTotalPriceStyledSpan,
  ShoppingCartWrapperStyledDiv,
} from '../Styles/ShoppingCartStyles';
import { ShoppingCartItem } from './ShoppingCartItem';
import { ShippingInfo } from './ShippingInfo';
import { sendGAnalyticsEvent } from '../GAnalytics/Main';

function computeTotalPriceInfo(shoppingCartTotalPrice: number): ReactNode {
  if (shoppingCartTotalPrice > 0) {
    return (
      <ShoppingCartTotalPriceStyledSpan>
        <b>Subtotal:</b>&nbsp;{shoppingCartTotalPrice}€
      </ShoppingCartTotalPriceStyledSpan>
    );
  }

  return null;
}

function computeTotalPriceInfoWithShipping(shoppingCartTotalPriceWithShipping: number): ReactNode {
  if (shoppingCartTotalPriceWithShipping > 0) {
    return (
      <ShoppingCartTotalPriceStyledSpan>
        <b>Total:</b>&nbsp;{shoppingCartTotalPriceWithShipping}€
      </ShoppingCartTotalPriceStyledSpan>
    );
  }

  return null;
}

function buildCheckoutButton(
  visibilityOfCheckingStock: boolean,
  checkoutButtonOnClick: () => void,
  visibilityOfChangeInStock: boolean,
): ReactNode {
  const notValidShoppingCartPopOver = (
    <Popover style={NotValidShoppingCartPopOverStyle}>
      <Popover.Body>
        La disponibilidad de alguno de los elementos del carrito ha cambiado. ¡Revísalo por favor!
      </Popover.Body>
    </Popover>
  );

  return (
    <ShoppingCartCheckoutWrapperStyledDiv>
      <OverlayTrigger
        trigger="click"
        show={visibilityOfChangeInStock}
        key="top"
        placement="top"
        overlay={notValidShoppingCartPopOver}>
        <ShoppingCartCheckoutButtonStyledButton
          onClick={() => {
            checkoutButtonOnClick();
          }}>
          {!visibilityOfCheckingStock ? 'Realizar pedido  ' : 'Comprobando stock  '}
          {!visibilityOfCheckingStock && <i className="bi bi-arrow-right" />}
          {visibilityOfCheckingStock && (
            <div className="spinner-border" role="status" style={CheckoutButtonLoadingSpinnerStyle} />
          )}
        </ShoppingCartCheckoutButtonStyledButton>
      </OverlayTrigger>
    </ShoppingCartCheckoutWrapperStyledDiv>
  );
}

function buildShoppingCartReactNode(
  shoppingCartContent: EarringSelection[],
  deleteItemOnClick: (index: number) => void,
  shoppingCartTotalPrice: number,
  shoppingCartTotalPriceWithShipping: number,
  setShoppingCartContent: Dispatch<SetStateAction<EarringSelection[] | null>>,
  visibilityOfCheckingStock: boolean,
  checkoutButtonOnClick: () => void,
  timeStamp: number,
  visibilityOfChangeInStock: boolean,
): ReactNode {
  const totalPriceInfo: ReactNode = computeTotalPriceInfo(shoppingCartTotalPrice);
  const totalPriceInfoWithShipping: ReactNode = computeTotalPriceInfoWithShipping(shoppingCartTotalPriceWithShipping);
  const checkoutButton: ReactNode = buildCheckoutButton(
    visibilityOfCheckingStock,
    checkoutButtonOnClick,
    visibilityOfChangeInStock,
  );

  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <ShoppingCartWrapperStyledDiv>
        <ShoppingCartGridStyledDiv>
          {shoppingCartContent.map((item, index) => (
            <ShoppingCartItem
              // timeStamp is needed to re-create the ShoppingCartItem
              // eslint-disable-next-line max-len -- It's a comment line...
              // eslint-disable-next-line react/no-array-index-key -- The same earring can be placed multiple times so the id is not enough
              key={`${item.earringID}_${item.color}_${index}_${timeStamp}`}
              earringSelection={item}
              positionInShoppingCart={index}
              deleteItemOnClick={() => deleteItemOnClick(index)}
              updateCartOnClick={(x: EarringSelection[]) => setShoppingCartContent(x)}
            />
          ))}
          {totalPriceInfo}
          <ShippingInfo totalPrice={shoppingCartTotalPrice} />
          {totalPriceInfoWithShipping}
          {checkoutButton}
        </ShoppingCartGridStyledDiv>
      </ShoppingCartWrapperStyledDiv>
    </div>
  );
}

function deleteElementInArray(
  index: number,
  setShoppingCartContent: Dispatch<SetStateAction<EarringSelection[] | null>>,
  shouldComputeTotalPrice: MutableRefObject<boolean>,
) {
  const localShoppingCartContent: EarringSelection[] | null = getShoppingCartContent();
  if (localShoppingCartContent) {
    // Delete te element from the shopping cart
    localShoppingCartContent.splice(index, 1);
    // Save change locally in local storage
    saveShoppingCartContent(localShoppingCartContent);
    // Save the new content (from local storage) to the state
    setShoppingCartContent(getShoppingCartContent());
    // eslint-disable-next-line no-param-reassign -- We explicitly need this
    shouldComputeTotalPrice.current = true;
    // We need to manually fire an event so the NavBar refresh the shopping cart icon
    // https://stackoverflow.com/a/65348883
    window.dispatchEvent(new Event('storage'));
  }
}

function ShoppingCart() {
  useEffect(() => {
    document.title = 'BySárago - Carrito';
  }, []);

  const navigate = useNavigate();

  const shouldComputeTotalPrice: MutableRefObject<boolean> = useRef(true);
  window.sessionStorage.removeItem(LOCAL_STORAGE_KEY_EARRING_GRID_EARRING_CARD_CLICKED_POSITION);
  const [shoppingCartContent, setShoppingCartContent] = useState<EarringSelection[] | null>(getShoppingCartContent());
  const [shoppingCartTotalPrice, setShoppingCartTotalPrice] = useState<number>(0.0);
  const [shoppingCartTotalPriceWithShipping, setShoppingCartTotalPriceWithShipping] = useState<number>(0.0);
  const [shoppingCartContentReactNode, setShoppingCartContentReactNode] = useState<ReactNode>();
  const [visibilityOfCheckingStock, setVisibilityOfCheckingStock] = useState<boolean>(false);
  const [visibilityOfChangeInStock, setVisibilityOfChangeInStock] = useState<boolean>(false);
  const [timeStamp, setTimeStamp] = useState<number>(new Date().getTime());

  useEffect(() => {
    if (shoppingCartContent && shoppingCartContent.length > 0) {
      const checkoutButtonOnClick = () => {
        sendGAnalyticsEvent('checkout_attempt', { shopping_cart: JSON.stringify(shoppingCartContent) });

        setVisibilityOfCheckingStock(true);
        isShoppingCartValid(shoppingCartContent).then(x => {
          if (!x.is_valid) {
            shouldComputeTotalPrice.current = true;
            setTimeStamp(new Date().getTime());

            setTimeout(() => {
              setVisibilityOfChangeInStock(true);
              setVisibilityOfCheckingStock(false);
            }, 300);

            setTimeout(() => {
              setVisibilityOfChangeInStock(false);
            }, 3000);
          } else {
            setVisibilityOfCheckingStock(false);
            navigate(`/checkout/`);
          }
        });
      };

      if (shouldComputeTotalPrice.current) {
        computeTotalPrice(shoppingCartContent).then(x => {
          setShoppingCartTotalPrice(x.total_price);
          setShoppingCartTotalPriceWithShipping(x.total_price_with_shipping);
        });
        shouldComputeTotalPrice.current = false;
      }
      // Build the react component for the shopping cart items and save it into the react state
      setShoppingCartContentReactNode(
        buildShoppingCartReactNode(
          shoppingCartContent,
          (index: number) => deleteElementInArray(index, setShoppingCartContent, shouldComputeTotalPrice),
          shoppingCartTotalPrice,
          shoppingCartTotalPriceWithShipping,
          setShoppingCartContent,
          visibilityOfCheckingStock,
          checkoutButtonOnClick,
          timeStamp,
          visibilityOfChangeInStock,
        ),
      );
    } else {
      const emptyCartMessage = (
        <ShoppingCartEmptyCartMessageStyledSpan>
          Todavía no tienes nada en el carrito.
          <br />
          ¡Echa un vistazo a los modelos disponibles!
        </ShoppingCartEmptyCartMessageStyledSpan>
      );
      setShoppingCartContentReactNode(emptyCartMessage);
    }
  }, [
    shoppingCartContent,
    shoppingCartTotalPrice,
    shoppingCartTotalPriceWithShipping,
    navigate,
    visibilityOfCheckingStock,
    timeStamp,
    visibilityOfChangeInStock,
  ]);

  return (
    <RootPagesStyledDiv>
      <RootPagesStyledHeader2>Carrito</RootPagesStyledHeader2>
      {shoppingCartContentReactNode}
    </RootPagesStyledDiv>
  );
}

export { ShoppingCart };
