import styled from 'styled-components';
import {
  deviceMediaQueries,
  PRODUCT_CARD_COLUMN_SIZE_MOBILE_VERTICAL,
  PRODUCT_CARD_COLUMN_SIZE_MOBILE_HORIZONTAL,
  PRODUCT_CARD_COLUMN_SIZE_TABLET_VERTICAL,
  PRODUCT_CARD_COLUMN_SIZE_TABLET_HORIZONTAL,
  PRODUCT_CARD_COLUMN_SIZE_LAPTOP,
} from '../Utils/ScreenSize';

export const EarringGridStyledDiv = styled.div`
  display: grid;
  justify-content: center;
  // Center in parents component: https://stackoverflow.com/a/1952287
  margin: 0px auto;
  max-height: 100%;

  @media only screen and ${deviceMediaQueries.mobile_vertical} {
    grid-gap: 1vh;
    grid-template-columns: repeat(auto-fill, ${PRODUCT_CARD_COLUMN_SIZE_MOBILE_VERTICAL}px);
    max-width: 100%;
  }

  @media only screen and ${deviceMediaQueries.mobile_horizontal} {
    grid-gap: 2vh;
    grid-template-columns: repeat(auto-fill, ${PRODUCT_CARD_COLUMN_SIZE_MOBILE_HORIZONTAL}px);
    max-width: 90%;
  }

  @media only screen and ${deviceMediaQueries.tablet_vertical} {
    grid-gap: 1vh;
    grid-template-columns: repeat(auto-fill, ${PRODUCT_CARD_COLUMN_SIZE_TABLET_VERTICAL}px);
    max-width: 90%;
  }

  @media only screen and ${deviceMediaQueries.tablet_horizontal} {
    grid-gap: 2vh;
    grid-template-columns: repeat(auto-fill, ${PRODUCT_CARD_COLUMN_SIZE_TABLET_HORIZONTAL}px);
    max-width: 95%;
  }

  @media only screen and ${deviceMediaQueries.laptop} {
    grid-gap: 2vh;
    grid-template-columns: repeat(auto-fill, ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP}px);
    max-width: 90%;
  }
`;
