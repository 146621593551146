import { ReactNode } from 'react';
import { ColorChangeHandler } from 'react-color';
import { ColorPicker } from '../Components/ColorPicker';

function buildColorPicker(
  selectedColor: string,
  colors: string[],
  onChange: ColorChangeHandler,
  withHash: boolean,
  circleSize: number,
  circleSpacing: number,
): ReactNode {
  return (
    <ColorPicker
      withHash={withHash}
      color={selectedColor}
      colors={colors}
      onChange={onChange}
      circleSize={circleSize}
      circleSpacing={circleSpacing}
    />
  );
}

export { buildColorPicker };
