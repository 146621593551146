import { useEffect } from 'react';
import { RootPagesStyledDiv, RootPagesStyledHeader2 } from '../../Styles/GeneralStyles';
import { LegalStatementTextStyledDiv, LegalStatementWrapperStyledDiv } from '../../Styles/LegalStatementStyles';
import { TermsAndConditionsStatement } from './TermsAndConditionsStaments';

function TermsAndConditions() {
  useEffect(() => {
    document.title = 'BySárago - Términos y condiciones';
  }, []);

  return (
    <RootPagesStyledDiv>
      <RootPagesStyledHeader2>Términos y Condiciones</RootPagesStyledHeader2>
      <LegalStatementWrapperStyledDiv>
        <LegalStatementTextStyledDiv>{TermsAndConditionsStatement()}</LegalStatementTextStyledDiv>
      </LegalStatementWrapperStyledDiv>
    </RootPagesStyledDiv>
  );
}

export { TermsAndConditions };
