import {
  ShippingInfoWrapperStyledDiv,
  ShippingInfoHeaderStyledSpan,
  ShippingInfoPriceStyledSpan,
  ShippingInfoDetailsStyledSpan,
} from '../Styles/ShippingInfoStyles';
import { FREE_IF_GREATER_THAN, SHIPPING_COSTS } from '../Utils/Constants';

interface ShippingInfoProps {
  totalPrice: number;
}

function buildShippingPriceInfo(totalPrice: number) {
  if (totalPrice > FREE_IF_GREATER_THAN) {
    return (
      <ShippingInfoPriceStyledSpan>
        <s
          style={{
            textDecorationThickness: 'var(--text_decoration_thickness)',
            textDecorationColor: 'var(--color_charcoal)',
          }}>
          {SHIPPING_COSTS}€
        </s>
        &nbsp;0€
      </ShippingInfoPriceStyledSpan>
    );
  }

  return <ShippingInfoPriceStyledSpan>{SHIPPING_COSTS}€</ShippingInfoPriceStyledSpan>;
}

function ShippingInfo(props: ShippingInfoProps) {
  const { totalPrice } = props;
  const shippingPriceInfo = buildShippingPriceInfo(totalPrice);
  return (
    <ShippingInfoWrapperStyledDiv>
      <ShippingInfoHeaderStyledSpan>Gastos de envío (realizado por Correos)</ShippingInfoHeaderStyledSpan>
      {shippingPriceInfo}
      <ShippingInfoDetailsStyledSpan>
        Envíos en 3-5 días hábiles. Gratis a partir de {FREE_IF_GREATER_THAN}€
      </ShippingInfoDetailsStyledSpan>
    </ShippingInfoWrapperStyledDiv>
  );
}

export { ShippingInfo };
