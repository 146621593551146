import { ChangeEvent, Dispatch, FormEvent, SetStateAction, useEffect, useState } from 'react';
import {
  AddressElement,
  LinkAuthenticationElement,
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { StripeAddressElementChangeEvent, StripeLinkAuthenticationElementChangeEvent } from '@stripe/stripe-js';
import { InputGroup, Form } from 'react-bootstrap';
import { getShoppingCartContent, isShoppingCartValid } from '../Utils/ShoppingCart';
import { updatePaymentIntent } from '../API/API';

interface CheckoutFormProps {
  totalPrice: number;
  paymentIntentId: string;
}

const PERSONAL_ID_NEEDED_MESSAGE = 'DNI necesario para envíos a Canarias, Ceuta y Melilla';

function buildEmailForm(setEmail: Dispatch<SetStateAction<string>>) {
  return (
    <form>
      <h4>Email</h4>
      <LinkAuthenticationElement
        onChange={(e: StripeLinkAuthenticationElementChangeEvent) => {
          setEmail(e.value.email);
        }}
      />
      <div
        style={{
          marginTop: '5px',
          fontWeight: '300',
          fontSize: '0.75rem',
          textAlign: 'start',
          color: 'var(--color_dark_grey)',
        }}>
        Necesitamos tu correo para enviarte una copia del pago e información de seguimiento del envío. Nunca te
        envíaremos correos comerciales.
      </div>
    </form>
  );
}

function isPersonalIdValid(x: string) {
  if (!x || x === '') {
    return false;
  }
  // https://regex101.com/r/yyvw7K/1
  const regex = /^(X(-|\.)?0?\d{7}(-|\.)?[A-Za-z]|[A-Za-z](-|\.)?\d{7}(-|\.)?[0-9A-Za-z]|\d{8}(-|\.)?[A-Za-z])$/;
  return regex.test(x);
}

// 35xxx: Gran Canaria postal codes
// 38xxx: Tenerife postal codes
// 51xxx: Ceuta postal codes
// 52xxx: Melilla postal codes
// https://en.wikipedia.org/wiki/Postal_codes_in_Spain#Two-digit_prefixes
function isPersonalIdNeeded(postalCode: string): boolean {
  return /^35[0-9]{3}$|^38[0-9]{3}$|^51[0-9]{3}$|^52[0-9]{3}$/.test(postalCode);
}

function buildAddressForm(
  postalCode: string,
  setPostalCode: Dispatch<SetStateAction<string>>,
  personalId: string,
  setPersonalId: Dispatch<SetStateAction<string>>,
) {
  return (
    <form>
      <h4>Dirección de envío</h4>
      <AddressElement
        onChange={(e: StripeAddressElementChangeEvent) => {
          setPostalCode(e.value.address.postal_code);
        }}
        options={{
          mode: 'shipping',
          allowedCountries: ['ES'],
          blockPoBox: true,
          fields: {
            phone: 'always',
          },
          // validation: {
          //   phone: {
          //     required: 'always',
          //   },
          // },
        }}
      />
      {isPersonalIdNeeded(postalCode) && (
        <InputGroup>
          <Form.Group
            style={{
              width: '100%',
              marginTop: '0.75rem',
            }}
            controlId="formDNI">
            <Form.Label
              style={{
                color: '#3c2f31',
                display: 'block',
                fontSize: '0.93rem',
                fontWeight: '400',
                lineHeight: '1.15',
                marginBottom: '0.25rem',
                textAlign: 'start',
                fontFamily:
                  // eslint-disable-next-line max-len -- We explicitly need this
                  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
              }}>
              {PERSONAL_ID_NEEDED_MESSAGE}
            </Form.Label>
            <Form.Control
              placeholder="12345678-A"
              className="form-control invalid"
              style={{
                border: '1px solid rgb(230, 230, 230)',
                borderRadius: '5px',
                boxShadow: '0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%)',
                lineHeight: '1.15',
                padding: '0.75rem',
              }}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setPersonalId(event.target.value);
              }}
              isInvalid={!isPersonalIdValid(personalId)}
            />
            <Form.Control.Feedback
              type="invalid"
              style={{
                color: '#df1b41',
                fontSize: '0.93rem',
                lineHeight: '1.15',
                textAlign: 'start',
              }}>
              Introduce un DNI válido
            </Form.Control.Feedback>
          </Form.Group>
        </InputGroup>
      )}
    </form>
  );
}

export default function CheckoutForm(props: CheckoutFormProps) {
  const { totalPrice, paymentIntentId } = props;
  const stripe = useStripe();
  const elements = useElements();

  const acceptPolicyMessage = 'Debes aceptar la política de privacidad';

  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const [validCart, setValidCart] = useState<boolean>(true);
  const [personalId, setPersonalId] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [privacyAccepted, setPrivacyAccepted] = useState<boolean>(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent!.status) {
        case 'succeeded':
          setMessage('El pago se ha realizado correctamente!');
          break;
        case 'processing':
          setMessage('El pago se está procesando.');
          break;
        case 'requires_payment_method':
          setMessage('El pago ha fallado, prueba de nuevo por favor.');
          break;
        default:
          setMessage('Algo ha fallado...');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    isShoppingCartValid(getShoppingCartContent()!).then(x => {
      setValidCart(x.is_valid);
    });

    if (validCart) {
      if (!privacyAccepted) {
        setMessage(acceptPolicyMessage);
        setTimeout(() => {
          setIsLoading(false);
        }, 200);
      } else if (isPersonalIdNeeded(postalCode) && (!personalId || !isPersonalIdValid(personalId))) {
        setMessage(PERSONAL_ID_NEEDED_MESSAGE);
        setTimeout(() => {
          setIsLoading(false);
        }, 200);
      } else {
        if (personalId) {
          updatePaymentIntent({ paymentIntentId, key: 'personal_id', value: personalId, metadata: true });
        }

        updatePaymentIntent({ paymentIntentId, key: 'receipt_email', value: email, metadata: false });

        const { protocol, hostname, port } = window.location;
        const baseDNS = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            payment_method_data: {
              billing_details: {
                address: {
                  country: 'ES',
                },
              },
            },
            return_url: `${baseDNS}/checkout_confirmation`,
          },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === 'card_error' || error.type === 'validation_error') {
          setMessage(error.message);
        } else {
          setMessage('Ocurrió un error inesperado.');
        }

        setIsLoading(false);
      }
    } else {
      setIsLoading(true);
      setTimeout(() => {
        setMessage('Alguno de los elementos del carrito ya no está disponible. ¡Revísalo y prueba de nuevo!');
        setIsLoading(false);
      }, 1000);
    }
  };

  return (
    <>
      {buildEmailForm(setEmail)}
      {buildAddressForm(postalCode, setPostalCode, personalId, setPersonalId)}
      <form id="payment-form" data-locale="auto" onSubmit={handleSubmit}>
        {/* Options are explained in:
      https://stripe.com/docs/js/elements_object/create_payment_element#payment_element_create-options */}
        <h4>Información de pago</h4>
        <PaymentElement
          id="payment-element"
          options={{
            layout: 'tabs',
            business: { name: 'BySárago' },
            fields: {
              billingDetails: {
                address: {
                  country: 'never',
                  postalCode: 'auto',
                },
              },
            },
            // terms: {
            //   card: 'always',
            // },
            wallets: {
              applePay: 'never',
              googlePay: 'never',
            },
          }}
        />
        <button
          type="submit"
          className="stripe-pay-button"
          disabled={isLoading || !stripe || !elements}
          id="stripe-pay-button-submit">
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner" /> : `Realizar pago: ${totalPrice}€`}
          </span>
        </button>
        <div className="d-flex">
          <Form.Group controlId="formBasicCheckbox">
            <div style={{ marginTop: '10px' }}>
              <Form.Check
                onChange={e => {
                  setPrivacyAccepted(e.target.checked);
                  if (message === acceptPolicyMessage && e.target.checked) {
                    setMessage('');
                  }
                }}
                type="checkbox"
                label={
                  <span>
                    He leído y acepto la&nbsp;
                    <a
                      style={{ color: 'var(--bysarago_color_purple_letters)' }}
                      aria-label="privacy"
                      href="/privacy"
                      target="_blank">
                      política de privacidad
                    </a>
                  </span>
                }
              />
            </div>
          </Form.Group>
        </div>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    </>
  );
}
