import { useEffect } from 'react';
import { RootPagesStyledDiv } from '../Styles/GeneralStyles';
import { EarringGrid } from './EarringGrid';

function Shop() {
  useEffect(() => {
    document.title = 'BySárago - Tienda';
  }, []);

  return (
    <RootPagesStyledDiv paddingBottom="0px" paddingTop="0px">
      <RootPagesStyledDiv id="RootPagesStyledDivHome">{EarringGrid(false)}</RootPagesStyledDiv>
    </RootPagesStyledDiv>
  );
}

export { Shop };
