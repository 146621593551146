import { MouseEventHandler, ReactNode } from 'react';
import { Earring, EarringMetalColor } from '../Models/EarringDataModel';
import { IMAGES_EARRINGS_PATH, GOLD_COLOR_HEX_CODE, SILVER_COLOR_HEX_CODE } from '../Utils/Constants';
import {
  EarringCardEarringMetalColorStyledSpan,
  EarringCardImageStyledDiv,
  EarringCardLabelImageStyledDiv,
  EarringCardMetalColorInfoBodyStyledP,
  EarringCardPriceStyledP,
  EarringCardNameStyledDiv,
  EarringCardWrappedStyledDiv,
} from '../Styles/EarringCardStyles';

function buildEarringMetalColorInfo(earringMetalColor: EarringMetalColor): ReactNode {
  let earringMetalColorInfo: ReactNode;
  if (earringMetalColor === EarringMetalColor.Gold) {
    earringMetalColorInfo = (
      <EarringCardMetalColorInfoBodyStyledP>
        Enganches:&nbsp;
        <EarringCardEarringMetalColorStyledSpan color={GOLD_COLOR_HEX_CODE} />
      </EarringCardMetalColorInfoBodyStyledP>
    );
  } else if (earringMetalColor === EarringMetalColor.Silver) {
    earringMetalColorInfo = (
      <EarringCardMetalColorInfoBodyStyledP>
        Enganches:&nbsp;
        <EarringCardEarringMetalColorStyledSpan color={SILVER_COLOR_HEX_CODE} />
      </EarringCardMetalColorInfoBodyStyledP>
    );
  } else if (earringMetalColor === EarringMetalColor.Both) {
    earringMetalColorInfo = (
      <EarringCardMetalColorInfoBodyStyledP>
        Enganches:&nbsp;
        <EarringCardEarringMetalColorStyledSpan color={GOLD_COLOR_HEX_CODE} />
        &nbsp;
        <EarringCardEarringMetalColorStyledSpan color={SILVER_COLOR_HEX_CODE} />
      </EarringCardMetalColorInfoBodyStyledP>
    );
  } else {
    earringMetalColorInfo = (
      <EarringCardMetalColorInfoBodyStyledP>Enganches no visibles</EarringCardMetalColorInfoBodyStyledP>
    );
  }

  return earringMetalColorInfo;
}

function buildPriceInfo(price: number, discountedPrice: number | null): ReactNode {
  let priceInfo: ReactNode;

  if (!discountedPrice) {
    priceInfo = <EarringCardPriceStyledP>{price}€</EarringCardPriceStyledP>;
  } else {
    priceInfo = (
      <EarringCardPriceStyledP>
        <s
          style={{
            textDecorationThickness: 'var(--text_decoration_thickness)',
            textDecorationColor: 'var(--color_light_grey)',
          }}>
          {price}€
        </s>
        &nbsp;&nbsp;{discountedPrice}€
      </EarringCardPriceStyledP>
    );
  }

  return priceInfo;
}

function buildMoreColorsInfo(colorsAndStock: { [key: string]: number }): ReactNode {
  let moreColorsInfo: ReactNode;

  if (Object.keys(colorsAndStock).length === 1) {
    moreColorsInfo = null;
  } else {
    moreColorsInfo = (
      <EarringCardLabelImageStyledDiv
        color="var(--bysarago_color_yellow)"
        textColor="var(--color_white)"
        horizontalPosition="left"
        verticalPosition="bottom">
        + colores
      </EarringCardLabelImageStyledDiv>
    );
  }

  return moreColorsInfo;
}

function buildStockInfo(colorsAndStock: { [key: string]: number }): ReactNode {
  let stock: ReactNode;
  const numberOfItems: number = Object.values(colorsAndStock).reduce<number>((accumulator, x) => {
    return accumulator + x;
  }, 0);

  if (numberOfItems === 0) {
    stock = (
      <EarringCardLabelImageStyledDiv
        color="var(--bysarago_color_purple_letters)"
        textColor="var(--color_white)"
        horizontalPosition="right"
        verticalPosition="bottom">
        Agotado
      </EarringCardLabelImageStyledDiv>
    );
  } else {
    stock = null;
  }

  return stock;
}

function buildNewnessInfo(newness: boolean): ReactNode {
  if (!newness) {
    return null;
  }
  return (
    <EarringCardLabelImageStyledDiv
      color="var(--bysarago_color_pink_dark)"
      textColor="var(--color_white)"
      horizontalPosition="left"
      verticalPosition="top"
      // top-left top-right bottom-right bottom-left
      borderRadius="0.6rem 0rem 0rem 0rem;">
      Novedad
    </EarringCardLabelImageStyledDiv>
  );
}

function buildOutletInfo(outlet: boolean): ReactNode {
  if (!outlet) {
    return null;
  }
  return (
    <EarringCardLabelImageStyledDiv
      color="var(--bysarago_color_red_light)"
      textColor="var(--color_white)"
      horizontalPosition="left"
      verticalPosition="top"
      // top-left top-right bottom-right bottom-left
      borderRadius="0.6rem 0rem 0rem 0rem;">
      Outlet
    </EarringCardLabelImageStyledDiv>
  );
}

interface EarringCardProps {
  earring: Earring;
  onClick: MouseEventHandler<HTMLDivElement>;
}

function EarringCard(props: EarringCardProps) {
  const { earring, onClick } = props;
  const { name, metalColor, price, discountedPrice, colorsAndStock } = earring;
  const earringMetalColorInfo: ReactNode = buildEarringMetalColorInfo(metalColor);
  const priceInfo: ReactNode = buildPriceInfo(price, discountedPrice);
  const moreColorsInfo: ReactNode = buildMoreColorsInfo(colorsAndStock);
  const stockInfo: ReactNode = buildStockInfo(colorsAndStock);
  const newnessInfo: ReactNode = buildNewnessInfo(earring.newness);
  const outletInfo: ReactNode = buildOutletInfo(earring.outlet);

  const mainImageUrl: string = `${IMAGES_EARRINGS_PATH}/${earring.id}/main.webp`;

  return (
    <EarringCardWrappedStyledDiv id={`EarringCard_${earring.id}`} onClick={onClick}>
      <EarringCardImageStyledDiv background={mainImageUrl} />
      {newnessInfo}
      {outletInfo}
      {moreColorsInfo}
      {stockInfo}
      <EarringCardNameStyledDiv>{name}</EarringCardNameStyledDiv>
      {earringMetalColorInfo}
      {priceInfo}
    </EarringCardWrappedStyledDiv>
  );
}

export { EarringCard };
