import styled from 'styled-components';

export const ShoppingCartItemWrapperStyledDiv = styled.div<{ inStock: boolean }>`
  background: ${({ inStock }) => (inStock ? 'var(--color-white)' : 'var(--color_very_light_grey)')};
  border-radius: 0.3rem;
  box-shadow: var(--base-box-shadow);
  display: grid;
  grid-template-areas:
    'image name remove'
    'image color empty'
    'image metal price';
  // 90px is ShoppingCartItemImageStyledDiv.width + 15px
  grid-template-columns: 85px calc((100% - 85px) * 0.8) calc((100% - 85px) * 0.2);
  grid-template-rows: 40px 30px 30px;
  text-align: start;
  width: 100%;
`;

export const ShoppingCartItemImageStyledDiv = styled.div<{ background: string; inStock: boolean }>`
  background-image: url(${({ background }) => background});
  background-size: 75px;
  border-bottom-left-radius: 0.3rem;
  border-top-left-radius: 0.3rem;
  cursor: pointer;
  filter: ${({ inStock }) => (inStock ? null : 'blur(2px)')};
  grid-area: image;
  height: 100px;
  width: 75px;
`;

export const ShoppingCartItemNameStyledH3 = styled.h3<{ inStock: boolean }>`
  align-items: center;
  color: var(--bysarago_color_purple_letters);
  cursor: pointer;
  display: flex;
  filter: ${({ inStock }) => (inStock ? null : 'blur(2px)')};
  font-weight: 350;
  grid-area: name;
  height: 100%;
  text-decoration: ${({ inStock }) => (inStock ? null : 'line-through')};
  width: fit-content;
`;

export const ShoppingCartItemColorInfoStyledSpan = styled.span<{ inStock: boolean }>`
  align-items: center;
  color: grey;
  display: flex;
  filter: ${({ inStock }) => (inStock ? null : 'blur(2px)')};
  font-size: 0.85rem;
  font-weight: 300;
  grid-area: color;
  height: 100%;
  text-decoration: ${({ inStock }) => (inStock ? null : 'line-through')};
  width: 100%;
`;

export const ShoppingCartItemMetalColorInfoStyledSpan = styled.span<{ inStock: boolean }>`
  align-items: center;
  color: grey;
  display: flex;
  filter: ${({ inStock }) => (inStock ? null : 'blur(2px)')};
  font-size: 0.85rem;
  font-weight: 300;
  grid-area: metal;
  height: 100%;
  text-decoration: ${({ inStock }) => (inStock ? null : 'line-through')};
  width: 100%;
`;

export const ShoppingCartItemPriceInfoStyledSpan = styled.span`
  align-items: center;
  display: flex;
  font-size: 1rem;
  grid-area: price;
  height: 100%;
  justify-content: end;
  // Keep in mind to update ShoppingCartTotalPriceStyledSpan
  padding-right: 10px;
  width: 100%;
`;

export const ShoppingCartItemRemoveItemStyledSpan = styled.span`
  align-items: center;
  color: grey;
  display: flex;
  font-size: 0.95rem;
  grid-area: remove;
  height: 100%;
  justify-content: end;
  // Keep in mind to update ShoppingCartTotalPriceStyledSpan
  padding-right: 10px;
  width: 100%;
`;

export const RemoveShoppingCartItemButtonStyle: { [key: string]: string } = {
  '--bs-btn-padding-x': '0',
  '--bs-btn-padding-y': '0',
};
