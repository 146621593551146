import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { deviceMediaQueries } from '../Utils/ScreenSize';

export const EarringProductDetailPageMainWrapperStyledDiv = styled.div`
  // Center in parents component: https://stackoverflow.com/a/1952287
  margin: 0px auto;
  max-height: 100%;

  text-align: center;

  @media only screen and ${deviceMediaQueries.mobile_vertical} {
    width: 100%;
  }

  @media only screen and ${deviceMediaQueries.mobile_horizontal} {
    width: 100%;
  }

  @media only screen and ${deviceMediaQueries.tablet_vertical} {
    width: 70%;
  }

  @media only screen and ${deviceMediaQueries.tablet_horizontal} {
    width: 100%;
  }

  @media only screen and ${deviceMediaQueries.laptop} {
    width: 100%;
  }
`;

export const EarringProductDetailPageWrapperStyledDiv = styled.div`
  // Center in parents component: https://stackoverflow.com/a/1952287
  margin: 0px auto;
  max-height: 100%;

  text-align: start;

  @media only screen and ${deviceMediaQueries.mobile_vertical} {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    flex-direction: column;
    max-width: 90%;
  }

  @media only screen and ${deviceMediaQueries.mobile_horizontal} {
    display: inline-flex;
    margin-top: 10px;
    margin-bottom: 10px;
    flex-direction: row;
    max-width: 90%;
  }

  @media only screen and ${deviceMediaQueries.tablet_vertical} {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    flex-direction: column;
    max-width: 90%;
  }

  @media only screen and ${deviceMediaQueries.tablet_horizontal} {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    flex-direction: row;
    max-width: 100%;
  }

  @media only screen and ${deviceMediaQueries.laptop} {
    display: inline-flex;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-direction: row;
    max-width: min(900px, calc(100% - 2vh));
  }
`;

export const EarringProductDetailPageInformationStyledDiv = styled.div`
  // Align text vertically
  align-items: center;
  display: grid;
  justify-content: center;

  @media only screen and ${deviceMediaQueries.mobile_vertical} {
    grid-template-areas:
      'name add_to_cart'
      'price price'
      'color_picker color_picker'
      'color_text color_text'
      'metal metal'
      'info info';
    grid-template-columns: 1fr auto;
    grid-template-rows: repeat(6, minmax(min-content, max-content));

    margin-top: 10px;
  }

  @media only screen and ${deviceMediaQueries.mobile_horizontal} {
    grid-template-areas:
      'name add_to_cart'
      'price price'
      'color_picker color_picker'
      'color_text color_text'
      'metal metal'
      'info info';
    grid-template-columns: 1fr auto;
    grid-template-rows: repeat(6, minmax(min-content, max-content));

    margin-left: 10px;
    max-width: calc(50% - 10px);
  }

  @media only screen and ${deviceMediaQueries.tablet_vertical} {
    grid-template-areas:
      'name add_to_cart'
      'price price'
      'color_picker color_picker'
      'color_text color_text'
      'metal metal'
      'info info';
    grid-template-columns: 1fr auto;
    grid-template-rows: repeat(6, minmax(min-content, max-content));

    margin-top: 10px;
    margin-bottom: 10px;
  }

  @media only screen and ${deviceMediaQueries.tablet_horizontal} {
    grid-template-areas:
      'name'
      'price'
      'color_picker'
      'color_text'
      'metal'
      'info'
      'add_to_cart';
    grid-template-columns: 100%;
    grid-template-rows: repeat(7, minmax(min-content, max-content));

    margin-top: 10px;
    margin-bottom: 10px;
  }

  @media only screen and ${deviceMediaQueries.laptop} {
    grid-template-areas:
      'name'
      'price'
      'color_picker'
      'color_text'
      'metal'
      'info'
      'add_to_cart';
    grid-template-columns: 100%;
    grid-template-rows: repeat(7, minmax(min-content, max-content));

    margin-left: 20px;
    max-width: calc(50% - 20px);
  }
`;

export const EarringPageNameStyledDiv = styled.div`
  color: var(--bysarago_color_purple_letters);
  display: flex;
  font-size: 1.5rem;
  grid-area: name;
`;

export const EarringPageImageGalleryStyledDiv = styled.div`
  display: flex;

  @media only screen and ${deviceMediaQueries.mobile_vertical} {
    flex-direction: column;
    max-width: 450px;
  }

  @media only screen and ${deviceMediaQueries.mobile_horizontal} {
    flex-direction: row;
    max-width: 300px;
  }

  @media only screen and ${deviceMediaQueries.tablet_vertical} {
    flex-direction: column;
    margin: 0px auto;
    max-width: 450px;
  }

  @media only screen and ${deviceMediaQueries.tablet_horizontal} {
    flex-direction: column;
    margin: 0px auto;
    max-width: 450px;
  }

  @media only screen and ${deviceMediaQueries.laptop} {
    flex-direction: row;
    max-width: 450px;
  }
`;

export const EarringPageTextSectionTitleStyledDiv = styled.div<{ fontSize?: string }>`
  font-size: ${p => (p.fontSize ? p.fontSize : '1rem')};
  font-weight: 450;
  margin-bottom: 0.1vh;

  // Display children items side by side
  display: flex;
  align-items: stretch;

  text-align: justify;
  text-justify: auto;
`;

export const EarringPageDescriptionStyledDiv = styled.div`
  display: block;
  font-weight: 300;
  white-space: pre-wrap;

  text-align: justify;
  text-justify: auto;
`;

export const EarringPageTextSectionWrapperStyledDiv = styled.div<{ gridArea: string }>`
  margin-top: 3px;
  grid-area: ${p => p.gridArea};
`;

export const EarringPageAddToShoppingCartWrapperStyledDiv = styled.div`
  width: 100%;
  display: flex;
  // When using media queries, change this to be center for mobile
  justify-content: start;
  align-items: start;

  @media only screen and ${deviceMediaQueries.mobile_vertical} {
    justify-content: end;
    align-items: center;
  }

  @media only screen and ${deviceMediaQueries.mobile_horizontal} {
    justify-content: end;
    align-items: center;
  }

  @media only screen and ${deviceMediaQueries.tablet_vertical} {
    justify-content: end;
    align-items: center;
  }

  @media only screen and ${deviceMediaQueries.laptop} {
    margin-top: 3px;
  }
`;

export const EarringPageAddToShoppingCartButtonStyledButton = styled(Button)`
  --bs-btn-color: var(--color_black);
  --bs-btn-bg: var(--bysarago_color_yellow);
  --bs-btn-border-color: var(--bysarago_color_yellow);
  --bs-btn-hover-color: var(--color_black);
  --bs-btn-hover-bg: var(--bysarago_color_yellow); // dark
  --bs-btn-hover-border-color: var(--bysarago_color_yellow); // dark
  --bs-btn-focus-gb: red;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--color_black);
  --bs-btn-active-bg: var(--bysarago_color_orange);
  --bs-btn-active-border-color: var(--bysarago_color_orange);
  --bs-btn-active-shadow: inset 0 3px 5pxrgba (0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--color_black);
  --bs-btn-disabled-bg: var(--color_light_grey);
  --bs-btn-disabled-border-color: var(--color_light_grey);

  box-shadow: var(--base-box-shadow);
  width: fit-content;
  font-size: 1rem;
`;

export const AddedToCartPopOverStyle: { [key: string]: string } = {
  '--bs-popover-bg': 'var(--color_green_confirmation)',
  textAlign: 'center',
};
