import styled from 'styled-components';
import {
  deviceMediaQueries,
  PRODUCT_CARD_COLUMN_SIZE_MOBILE_VERTICAL,
  PRODUCT_CARD_COLUMN_SIZE_MOBILE_HORIZONTAL,
  PRODUCT_CARD_COLUMN_SIZE_TABLET_VERTICAL,
  PRODUCT_CARD_COLUMN_SIZE_TABLET_HORIZONTAL,
  PRODUCT_CARD_COLUMN_SIZE_LAPTOP,
  PRODUCT_CARD_LAPTOP_IMAGE_SECTION_SIZE,
  PRODUCT_CARD_LAPTOP_TITLE_SECTION_SIZE,
  PRODUCT_CARD_LAPTOP_METALCOLOR_SECTION_SIZE,
  PRODUCT_CARD_LAPTOP_PRICE_SECTION_SIZE,
} from '../Utils/ScreenSize';

export const EarringCardWrappedStyledDiv = styled.div`
  background: var(--color_white);
  border-radius: 0.6rem;
  box-shadow: var(--base-box-shadow);
  cursor: pointer;
  display: grid;
  grid-template-areas: 'image' 'name' 'metalcolor' 'price';
  text-align: center;

  @media only screen and ${deviceMediaQueries.mobile_vertical} {
    grid-template-columns: ${PRODUCT_CARD_COLUMN_SIZE_MOBILE_VERTICAL}px;
    grid-template-rows:
      calc(
        ${PRODUCT_CARD_COLUMN_SIZE_MOBILE_VERTICAL}px * ${PRODUCT_CARD_LAPTOP_IMAGE_SECTION_SIZE} /
          ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP}
      )
      calc(
        ${PRODUCT_CARD_COLUMN_SIZE_MOBILE_VERTICAL}px * ${PRODUCT_CARD_LAPTOP_TITLE_SECTION_SIZE} /
          ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP}
      )
      calc(
        ${PRODUCT_CARD_COLUMN_SIZE_MOBILE_VERTICAL}px * ${PRODUCT_CARD_LAPTOP_METALCOLOR_SECTION_SIZE} /
          ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP}
      )
      calc(
        ${PRODUCT_CARD_COLUMN_SIZE_MOBILE_VERTICAL}px * ${PRODUCT_CARD_LAPTOP_PRICE_SECTION_SIZE} /
          ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP}
      );
  }

  @media only screen and ${deviceMediaQueries.mobile_horizontal} {
    grid-template-columns: ${PRODUCT_CARD_COLUMN_SIZE_MOBILE_HORIZONTAL}px;
    grid-template-rows:
      calc(
        ${PRODUCT_CARD_COLUMN_SIZE_MOBILE_HORIZONTAL}px * ${PRODUCT_CARD_LAPTOP_IMAGE_SECTION_SIZE} /
          ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP}
      )
      calc(
        ${PRODUCT_CARD_COLUMN_SIZE_MOBILE_HORIZONTAL}px * ${PRODUCT_CARD_LAPTOP_TITLE_SECTION_SIZE} /
          ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP}
      )
      calc(
        ${PRODUCT_CARD_COLUMN_SIZE_MOBILE_HORIZONTAL}px * ${PRODUCT_CARD_LAPTOP_METALCOLOR_SECTION_SIZE} /
          ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP}
      )
      calc(
        ${PRODUCT_CARD_COLUMN_SIZE_MOBILE_HORIZONTAL}px * ${PRODUCT_CARD_LAPTOP_PRICE_SECTION_SIZE} /
          ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP}
      );
  }

  @media only screen and ${deviceMediaQueries.tablet_vertical} {
    grid-template-columns: ${PRODUCT_CARD_COLUMN_SIZE_TABLET_VERTICAL}px;
    grid-template-rows:
      calc(
        ${PRODUCT_CARD_COLUMN_SIZE_TABLET_VERTICAL}px * ${PRODUCT_CARD_LAPTOP_IMAGE_SECTION_SIZE} /
          ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP}
      )
      calc(
        ${PRODUCT_CARD_COLUMN_SIZE_TABLET_VERTICAL}px * ${PRODUCT_CARD_LAPTOP_TITLE_SECTION_SIZE} /
          ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP}
      )
      calc(
        ${PRODUCT_CARD_COLUMN_SIZE_TABLET_VERTICAL}px * ${PRODUCT_CARD_LAPTOP_METALCOLOR_SECTION_SIZE} /
          ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP}
      )
      calc(
        ${PRODUCT_CARD_COLUMN_SIZE_TABLET_VERTICAL}px * ${PRODUCT_CARD_LAPTOP_PRICE_SECTION_SIZE} /
          ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP}
      );
  }

  @media only screen and ${deviceMediaQueries.tablet_horizontal} {
    grid-template-columns: ${PRODUCT_CARD_COLUMN_SIZE_TABLET_HORIZONTAL}px;
    grid-template-rows:
      calc(
        ${PRODUCT_CARD_COLUMN_SIZE_TABLET_HORIZONTAL}px * ${PRODUCT_CARD_LAPTOP_IMAGE_SECTION_SIZE} /
          ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP}
      )
      calc(
        ${PRODUCT_CARD_COLUMN_SIZE_TABLET_HORIZONTAL}px * ${PRODUCT_CARD_LAPTOP_TITLE_SECTION_SIZE} /
          ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP}
      )
      calc(
        ${PRODUCT_CARD_COLUMN_SIZE_TABLET_HORIZONTAL}px * ${PRODUCT_CARD_LAPTOP_METALCOLOR_SECTION_SIZE} /
          ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP}
      )
      calc(
        ${PRODUCT_CARD_COLUMN_SIZE_TABLET_HORIZONTAL}px * ${PRODUCT_CARD_LAPTOP_PRICE_SECTION_SIZE} /
          ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP}
      );
  }

  @media only screen and ${deviceMediaQueries.laptop} {
    grid-template-columns: ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP}px;
    grid-template-rows:
      ${PRODUCT_CARD_LAPTOP_IMAGE_SECTION_SIZE}px
      ${PRODUCT_CARD_LAPTOP_TITLE_SECTION_SIZE}px
      ${PRODUCT_CARD_LAPTOP_METALCOLOR_SECTION_SIZE}px
      ${PRODUCT_CARD_LAPTOP_PRICE_SECTION_SIZE}px;
  }
`;

export const EarringCardImageStyledDiv = styled.div<{ background: string }>`
  background-image: url(${({ background }) => background});
  background-size: cover;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  grid-area: image;
`;

export const EarringCardNameStyledDiv = styled.div`
  align-items: center;
  color: var(--bysarago_color_purple_letters);
  display: flex;
  grid-area: name;
  height: 100%;
  justify-content: center;
  width: 100%;

  @media only screen and ${deviceMediaQueries.mobile_vertical} {
    font-size: calc(${PRODUCT_CARD_COLUMN_SIZE_MOBILE_VERTICAL} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 2rem);
  }

  @media only screen and ${deviceMediaQueries.mobile_horizontal} {
    font-size: calc(${PRODUCT_CARD_COLUMN_SIZE_MOBILE_HORIZONTAL} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 2rem);
  }

  @media only screen and ${deviceMediaQueries.tablet_vertical} {
    font-size: calc(${PRODUCT_CARD_COLUMN_SIZE_TABLET_VERTICAL} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 2rem);
  }

  @media only screen and ${deviceMediaQueries.tablet_horizontal} {
    font-size: calc(${PRODUCT_CARD_COLUMN_SIZE_TABLET_HORIZONTAL} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 2rem);
  }

  @media only screen and ${deviceMediaQueries.laptop} {
    font-size: calc(${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 2rem);
  }
`;

export const EarringCardMetalColorInfoBodyStyledP = styled.p`
  align-items: center;
  color: grey;
  display: flex;
  font-weight: 300;
  grid-area: metalcolor;
  height: 100%;
  justify-content: center;
  width: 100%;

  @media only screen and ${deviceMediaQueries.mobile_vertical} {
    font-size: calc(${PRODUCT_CARD_COLUMN_SIZE_MOBILE_VERTICAL} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 1.1rem);
  }

  @media only screen and ${deviceMediaQueries.mobile_horizontal} {
    font-size: calc(${PRODUCT_CARD_COLUMN_SIZE_MOBILE_HORIZONTAL} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 1.1rem);
  }

  @media only screen and ${deviceMediaQueries.tablet_vertical} {
    font-size: calc(${PRODUCT_CARD_COLUMN_SIZE_TABLET_VERTICAL} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 0.9rem);
  }

  @media only screen and ${deviceMediaQueries.tablet_horizontal} {
    font-size: calc(${PRODUCT_CARD_COLUMN_SIZE_TABLET_HORIZONTAL} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 0.9rem);
  }

  @media only screen and ${deviceMediaQueries.laptop} {
    font-size: calc(${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 0.9rem);
  }
`;

export const EarringCardPriceStyledP = styled.p`
  align-items: center;
  color: grey;
  display: flex;
  font-weight: 400;
  grid-area: price;
  height: 100%;
  justify-content: center;
  width: 100%;

  @media only screen and ${deviceMediaQueries.mobile_vertical} {
    font-size: calc(${PRODUCT_CARD_COLUMN_SIZE_MOBILE_VERTICAL} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 1.4rem);
  }

  @media only screen and ${deviceMediaQueries.mobile_horizontal} {
    font-size: calc(${PRODUCT_CARD_COLUMN_SIZE_MOBILE_HORIZONTAL} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 1.4rem);
  }

  @media only screen and ${deviceMediaQueries.tablet_vertical} {
    font-size: calc(${PRODUCT_CARD_COLUMN_SIZE_TABLET_VERTICAL} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 1.4rem);
  }

  @media only screen and ${deviceMediaQueries.tablet_horizontal} {
    font-size: calc(${PRODUCT_CARD_COLUMN_SIZE_TABLET_HORIZONTAL} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 1.4rem);
  }

  @media only screen and ${deviceMediaQueries.laptop} {
    font-size: calc(${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 1.4rem);
  }
`;

export const EarringCardEarringMetalColorStyledSpan = styled.span<{ color: string }>`
  background-color: ${({ color }) => color};
  border-radius: 50%;
  display: inline-block;

  @media only screen and ${deviceMediaQueries.mobile_vertical} {
    height: calc(${PRODUCT_CARD_COLUMN_SIZE_MOBILE_VERTICAL} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 0.65rem);
    width: calc(${PRODUCT_CARD_COLUMN_SIZE_MOBILE_VERTICAL} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 0.65rem);
  }

  @media only screen and ${deviceMediaQueries.mobile_horizontal} {
    height: calc(${PRODUCT_CARD_COLUMN_SIZE_MOBILE_HORIZONTAL} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 0.65rem);
    width: calc(${PRODUCT_CARD_COLUMN_SIZE_MOBILE_HORIZONTAL} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 0.65rem);
  }

  @media only screen and ${deviceMediaQueries.tablet_vertical} {
    height: calc(${PRODUCT_CARD_COLUMN_SIZE_TABLET_VERTICAL} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 0.65rem);
    width: calc(${PRODUCT_CARD_COLUMN_SIZE_TABLET_VERTICAL} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 0.65rem);
  }

  @media only screen and ${deviceMediaQueries.tablet_horizontal} {
    height: calc(${PRODUCT_CARD_COLUMN_SIZE_TABLET_HORIZONTAL} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 0.65rem);
    width: calc(${PRODUCT_CARD_COLUMN_SIZE_TABLET_HORIZONTAL} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 0.65rem);
  }

  @media only screen and ${deviceMediaQueries.laptop} {
    height: calc(${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 0.65rem);
    width: calc(${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 0.65rem);
  }
`;

export const EarringCardLabelImageStyledDiv = styled.div<{
  color: string;
  textColor: string;
  verticalPosition: string;
  horizontalPosition: string;
  borderRadius?: string;
}>`
  align-items: center;
  align-self: ${({ verticalPosition }) => (verticalPosition === 'top' ? 'start' : 'end')};
  background-color: ${({ color }) => color};
  color: ${({ textColor }) => textColor};
  display: flex;
  grid-area: image;
  height: 8%;
  justify-content: center;
  margin-left: ${({ horizontalPosition }) => (horizontalPosition === 'left' ? '0%' : '50%')};
  text-align: center;
  width: 50%;
  border-radius: ${({ borderRadius }) => borderRadius || 'none'};

  @media only screen and ${deviceMediaQueries.mobile_vertical} {
    font-size: calc(${PRODUCT_CARD_COLUMN_SIZE_MOBILE_VERTICAL} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 1.2rem);
  }

  @media only screen and ${deviceMediaQueries.mobile_horizontal} {
    font-size: calc(${PRODUCT_CARD_COLUMN_SIZE_MOBILE_HORIZONTAL} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 1.2rem);
  }

  @media only screen and ${deviceMediaQueries.tablet_vertical} {
    font-size: calc(${PRODUCT_CARD_COLUMN_SIZE_TABLET_VERTICAL} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 1.2rem);
  }

  @media only screen and ${deviceMediaQueries.tablet_horizontal} {
    font-size: calc(${PRODUCT_CARD_COLUMN_SIZE_TABLET_HORIZONTAL} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 1.2rem);
  }

  @media only screen and ${deviceMediaQueries.laptop} {
    font-size: calc(${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} / ${PRODUCT_CARD_COLUMN_SIZE_LAPTOP} * 1.2rem);
  }
`;
