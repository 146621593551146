import { useMediaQuery } from 'react-responsive';

// https://css-tricks.com/snippets/css/media-queries-for-standard-devices/
const deviceMediaQueries = {
  mobile_vertical: `(max-width: 479px) and (orientation: portrait)`,
  mobile_horizontal: `(min-width: 480px) and (max-width: 840px) and (orientation: landscape)`,
  tablet_vertical: `(min-width: 480px) and (max-width: 1023px) and (orientation: portrait)`,
  tablet_horizontal: `(min-width: 840px) and (max-width: 1279px) and (orientation: landscape)`,
  laptop: `(min-width: 900px)`,
};

export const PRODUCT_CARD_COLUMN_SIZE_MOBILE_VERTICAL = 180;
export const PRODUCT_CARD_COLUMN_SIZE_MOBILE_HORIZONTAL = 140;
export const PRODUCT_CARD_COLUMN_SIZE_TABLET_VERTICAL = 270;
export const PRODUCT_CARD_COLUMN_SIZE_TABLET_HORIZONTAL = 290;
export const PRODUCT_CARD_COLUMN_SIZE_LAPTOP = 300;

export const PRODUCT_CARD_LAPTOP_IMAGE_SECTION_SIZE = 400;
export const PRODUCT_CARD_LAPTOP_TITLE_SECTION_SIZE = 50;
export const PRODUCT_CARD_LAPTOP_METALCOLOR_SECTION_SIZE = 25;
export const PRODUCT_CARD_LAPTOP_PRICE_SECTION_SIZE = 45;

function CalculateImageHeightsForGallery() {
  const isMobileVertical: boolean = useMediaQuery({ query: deviceMediaQueries.mobile_vertical });
  const isMobileHorizontal: boolean = useMediaQuery({ query: deviceMediaQueries.mobile_horizontal });
  const isVerticalTablet: boolean = useMediaQuery({ query: deviceMediaQueries.tablet_vertical });
  const isHorizontalTablet: boolean = useMediaQuery({ query: deviceMediaQueries.tablet_horizontal });
  const isLaptop: boolean = useMediaQuery({ query: deviceMediaQueries.laptop });

  const heightsForImages: { [key: string]: number } = {
    mobileVertical: 350,
    mobileHorizontal: 350,
    tabletVertical: 450,
    tabletHorizontal: 400,
    laptop: 600,
  };

  let heightForImages: number = 600;
  if (isMobileVertical) {
    heightForImages = heightsForImages.mobileVertical;
  } else if (isMobileHorizontal) {
    heightForImages = heightsForImages.mobileHorizontal;
  } else if (isVerticalTablet) {
    heightForImages = heightsForImages.tabletVertical;
  } else if (isHorizontalTablet) {
    heightForImages = heightsForImages.tabletHorizontal;
  } else if (isLaptop) {
    heightForImages = heightsForImages.laptop;
  }

  return heightForImages;
}

export { deviceMediaQueries, CalculateImageHeightsForGallery };
