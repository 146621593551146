import { Spinner } from 'react-bootstrap';

interface LoadingSpinnerProps {
  spinnerSize: string;
  margin: string;
}

function LoadingSpinner(props: LoadingSpinnerProps) {
  const { spinnerSize, margin } = props;

  const LoadingSpinnerStyle: { [key: string]: string } = {
    color: 'var(--bysarago_color_purple_letters)',
    '--bs-spinner-width': spinnerSize,
    '--bs-spinner-height': spinnerSize,
    margin,
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Spinner style={LoadingSpinnerStyle} animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
}

export { LoadingSpinner };
