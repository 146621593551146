import styled from 'styled-components';

export const ShippingInfoWrapperStyledDiv = styled.div`
  background: var(--color-white);
  border-radius: 0.3rem;
  box-shadow: var(--base-box-shadow);
  display: grid;
  grid-template-areas: 'header price' 'details price';
  grid-template-columns: 75% 25%;
  grid-template-rows: min-content min-content;
  text-align: start;
  width: 100%;
`;

export const ShippingInfoHeaderStyledSpan = styled.span`
  align-items: center;
  color: var(--color_black);
  display: flex;
  font-size: 0.8rem;
  font-weight: bold;
  grid-area: header;
  height: 100%;
  margin-left: 10px;
  width: 100%;
`;

export const ShippingInfoPriceStyledSpan = styled.span`
  align-items: center;
  color: var(--color_black);
  display: flex;
  font-size: 0.8rem;
  grid-area: price;
  height: 100%;
  justify-content: end;
  padding-right: 10px;
  width: 100%;
`;

export const ShippingInfoDetailsStyledSpan = styled.span`
  align-items: center;
  color: grey;
  display: flex;
  font-size: 0.7rem;
  font-weight: 300;
  grid-area: details;
  height: 100%;
  margin-left: 10px;
  width: 100%;
`;
