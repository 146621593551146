export enum EarringMaterial {
  PolimerClay = 'PolimerClay',
  ResinUV = 'ResinUV',
}

export enum EarringMetalColor {
  Gold = 'Gold',
  Silver = 'Silver',
  Both = 'Both',
  NotVisible = 'NotVisible',
}

type Earring = {
  id: string; // sha256
  type: string; // 'earring'
  date: string; // ISO 8601
  name: string;
  description: string;
  price: number;
  discountedPrice: number | null;
  /**
   * Relevant characteristics of the earring. For example:
   *   {
   *       shape: 'circle'
   *       material: 'clay'
   *       size: 'clay'
   *   }
   *
   * Used for future filtering
   */
  characteristics: { [key: string]: string } | null;
  /**
   * A few notes:
   *   - keys (colors) in this map represent the main color
   *   - keys (colors) in this map should match <color_X> paths in S3
   *   - if (Object.keys(colorsAndStock).length) === 1:
   *       - Single color earring: no color selector should be displayed in the earring detailed view page
   *       - Stock is Object.values(colorsAndStock)[0]
   *       - images are in ${imagesPath}/Object.keys(colorsAndStock)[0]
   *   - if (Object.keys(colorsAndStock).length) !== 1:
   *       - Multiple color earring: color selector should be displayed in the earring detailed view page
   *       - Stock is sum(Object.values(colorsAndStock))
   */
  colorsAndStock: { [key: string]: number }; // hex > #
  /**
   * Order of the colors. A few notes:
   *   - colorOrder.lenght should be === to Object.key(colorsAndStock).length
   *   - Main color of the earring === colorsAndStock[colorOder[0]]
   */
  colorsOrder: string[];
  /**
   * A map "translating" the hex value of a color to a user friendly color name
   */
  colorHexToString: { [key: string]: string }; // hex > <name>
  metalColor: EarringMetalColor;
  /**
   * Index of the position in the earring collection when this earring is placed. Used for custom
   * ordering the earrings
   */
  orderPosition: number;
  collection: string | null;
  /**
   * Flag used to control if an earring is new or not
   */
  newness: boolean;
  /**
   * Flag used to control if an earring is in outlet or not
   */
  outlet: boolean;
  /**
   * Earring material
   */
  material: EarringMaterial;
  /**
   * Flag used to control if an earring is visible or not
   */
  visible: boolean;
};

type EarringSelection = {
  /**
   * ID of the earring selected
   */
  earringID: string;
  /**
   * Earring color selected
   */
  color: string;
  /**
   * Earring metal color selected: null if not decided yet
   */
  metalColor: EarringMetalColor;
};

export type { Earring, EarringSelection };
