export const IMAGES_BASE_PATH = process.env.REACT_APP_IMAGES_ENDPOINT_URL;
export const IMAGES_EARRINGS_PATH = `${IMAGES_BASE_PATH}/images/earrings`;

export const GOLD_COLOR_HEX_CODE = '#ffd700';
export const SILVER_COLOR_HEX_CODE = '#c0c0c0';

export const LOCAL_STORAGE_KEY_EARRING_GRID_EARRING_CARD_CLICKED_POSITION = 'EarringGridEarringCardClickedPosition';

export const LOCAL_STORAGE_KEY_SHOPPING_CART_CONTENT = 'ShoppingCartContent';

export const CIRCLE_COLOR_PICKER_CIRCLE_SIZE = 28;
export const CIRCLE_COLOR_PICKER_CIRCLE_SPACING = 5;

export const FREE_IF_GREATER_THAN: number = 40;
export const SHIPPING_COSTS: number = 3.99;
