import { Color, ColorChangeHandler, CirclePicker } from 'react-color';
import React from 'react';

interface ColorPickerProps {
  withHash: boolean;
  color: Color;
  colors: string[];
  onChange: ColorChangeHandler;
  circleSize: number;
  circleSpacing: number;
}

interface ColorPickerState {
  color: Color;
  colors: string[];
}

export class ColorPicker extends React.Component<ColorPickerProps, ColorPickerState> {
  constructor(props: ColorPickerProps) {
    super(props);
    const { color, colors, withHash } = this.props;
    this.state = {
      color: withHash ? color : `#${color}`,
      colors: withHash ? colors : colors.map(x => `#${x}`),
    };
  }

  handleChange: ColorChangeHandler = (color, event) => {
    this.setState({ color: color.rgb }); // update internal state
    const { onChange } = this.props;
    onChange(color, event); // send the new value 'up' to the parent
  };

  render() {
    const { color, colors } = this.state;
    const { circleSize, circleSpacing } = this.props;
    const whiteColor = { r: 255, g: 255, b: 255, a: 1 } as Color;
    const className =
      JSON.stringify(color) === JSON.stringify(whiteColor) || color === '#ffffff' ? 'circle-picker-white-selected' : '';

    return (
      <div>
        <CirclePicker
          circleSpacing={circleSpacing}
          circleSize={circleSize}
          className={className}
          color={color}
          colors={colors}
          width="fit-content"
          onChange={this.handleChange}
        />
      </div>
    );
  }
}
