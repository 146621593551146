import { axiosFetch } from './Base';
import { Earring } from '../Models/EarringDataModel';
import { EarringsTotalPrice, PaymentIntent, ShoppingCartValidity, UpdatePaymentIntent } from '../Models/API';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export async function getEarrings(outlet: boolean): Promise<Earring[]> {
  const result: Promise<Earring[]> = axiosFetch({
    endpoint: `${BACKEND_URL}/earrings/?outlet=${outlet}`,
    method: 'GET',
    withCredentials: true,
  });

  return result;
}

export async function getEarring(params: { earringID?: string }): Promise<Earring> {
  const { earringID } = params;

  if (!earringID) throw new Error('earringID is missing.');

  const result: Promise<Earring> = axiosFetch({
    endpoint: `${BACKEND_URL}/earrings/${earringID}`,
    method: 'GET',
    withCredentials: true,
  });

  return result;
}

export async function getEarringsTotalPrice(params: {
  earringIDs: string[];
  earringColors: string[];
}): Promise<EarringsTotalPrice> {
  const { earringIDs, earringColors } = params;
  const earringIDsParameter = earringIDs ? earringIDs.map(n => `earring_ids=${n}`).join('&') : null;
  const earringColorsParameter = earringColors ? earringColors.map(n => `earring_colors=${n}`).join('&') : null;

  const result: Promise<EarringsTotalPrice> = axiosFetch({
    endpoint: `${BACKEND_URL}/total_price?${earringIDsParameter}&${earringColorsParameter}`,
    method: 'GET',
    withCredentials: true,
  });

  return result;
}

export async function checkIfShoppingCartIsValid(params: {
  earringIDs: string[];
  earringColors: string[];
}): Promise<ShoppingCartValidity> {
  const { earringIDs, earringColors } = params;

  if (!earringIDs) throw new Error('earringIDs is missing.');
  if (!earringColors) throw new Error('earringColors is missing.');
  if (earringIDs.length !== earringColors.length) throw new Error('Missing elements.');

  const earringIDsParameter = earringIDs ? earringIDs.map(n => `earring_ids=${n}`).join('&') : null;
  const earringColorsParameter = earringColors ? earringColors.map(n => `earring_colors=${n}`).join('&') : null;

  const result: Promise<ShoppingCartValidity> = axiosFetch({
    endpoint: `${BACKEND_URL}/validate_cart?${earringIDsParameter}&${earringColorsParameter}`,
    method: 'GET',
    withCredentials: true,
  });

  return result;
}

export async function createPaymentIntent(params: {
  earringIDs: string[];
  earringColors: string[];
  earringMetalColors: string[];
}): Promise<PaymentIntent> {
  const { earringIDs, earringColors, earringMetalColors } = params;

  const result: Promise<PaymentIntent> = axiosFetch({
    endpoint: `${BACKEND_URL}/create_payment_intent`,
    method: 'POST',
    withCredentials: true,
    data: { earring_ids: earringIDs, earring_colors: earringColors, earring_metal_colors: earringMetalColors },
  });

  return result;
}

export async function updatePaymentIntent(params: {
  paymentIntentId: string;
  key: string;
  value: string;
  metadata: boolean;
}): Promise<UpdatePaymentIntent> {
  const { paymentIntentId, key, value, metadata } = params;

  const result: Promise<UpdatePaymentIntent> = axiosFetch({
    endpoint: `${BACKEND_URL}/update_payment_intent`,
    method: 'POST',
    withCredentials: true,
    data: { payment_intent_id: paymentIntentId, key, value, metadata },
  });

  return result;
}
