import { useEffect } from 'react';
import { RootPagesStyledDiv } from '../Styles/GeneralStyles';
import { EarringGrid } from './EarringGrid';

function Outlet() {
  useEffect(() => {
    document.title = 'BySárago - Outlet';
  }, []);

  return (
    <RootPagesStyledDiv paddingBottom="0px" paddingTop="0px">
      <RootPagesStyledDiv id="RootPagesStyledDivHome">{EarringGrid(true)}</RootPagesStyledDiv>
    </RootPagesStyledDiv>
  );
}

export { Outlet };
