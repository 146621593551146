import styled from 'styled-components';
import { deviceMediaQueries } from '../Utils/ScreenSize';

export const LegalStatementWrapperStyledDiv = styled.div`
  // Center in parents component: https://stackoverflow.com/a/1952287
  margin: 0px auto;
  max-height: 100%;
  max-width: 85%;
`;

export const LegalStatementTextStyledDiv = styled.div`
  // Center in parents component: https://stackoverflow.com/a/1952287
  margin: 0px auto;
  max-height: 100%;

  text-align: justify;
  text-justify: auto;

  @media only screen and ${deviceMediaQueries.mobile_vertical} {
    max-width: 100%;
  }

  @media only screen and ${deviceMediaQueries.mobile_horizontal} {
    max-width: 90%;
  }

  @media only screen and ${deviceMediaQueries.tablet_vertical} {
    max-width: 90%;
  }

  @media only screen and ${deviceMediaQueries.tablet_horizontal} {
    max-width: 70%;
  }

  @media only screen and ${deviceMediaQueries.laptop} {
    max-width: 70%;
  }
`;
