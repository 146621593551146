import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { deviceMediaQueries } from '../Utils/ScreenSize';

export const ShoppingCartWrapperStyledDiv = styled.div`
  text-align: start;

  @media only screen and ${deviceMediaQueries.mobile_vertical} {
    display: inline-flex;
    margin: 0px 10px 0px 10px; // top right bottom left
    width: 95%;
  }

  @media only screen and ${deviceMediaQueries.mobile_horizontal} {
    display: inline-flex;
    margin: 0px 10px 0px 10px; // top right bottom left
    width: 70%;
  }

  @media only screen and ${deviceMediaQueries.tablet_vertical} {
    display: inline-flex;
    margin: 0px 15px 0px 15px; // top right bottom left
    width: min(600px, 70%);
  }

  @media only screen and ${deviceMediaQueries.tablet_horizontal} {
    display: inline-flex;
    margin: 0px 50px 0px 50px; // top right bottom left
    width: 70%;
  }

  @media only screen and ${deviceMediaQueries.laptop} {
    display: inline-flex;
    margin: 0px 50px 0px 50px; // top right bottom left
    width: min(600px, 65%);
  }
`;

export const ShoppingCartGridStyledDiv = styled.div`
  display: grid;
  grid-gap: 1vh;
  grid-template-rows: repeat(auto-fill);
  width: 100%;
`;

export const ShoppingCartTotalPriceStyledSpan = styled.span`
  display: inline-flex;
  justify-content: end;
  padding-right: 10px;
  font-size: 1.15rem;
`;

export const ShoppingCartEmptyCartMessageStyledSpan = styled.span`
  font-weight: 300;
  text-align: center;
`;

export const ShoppingCartCheckoutWrapperStyledDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ShoppingCartCheckoutButtonStyledButton = styled(Button)`
  --bs-btn-color: var(--color_black);
  --bs-btn-bg: var(--bysarago_color_yellow);
  --bs-btn-border-color: var(--bysarago_color_yellow);
  --bs-btn-hover-color: var(--color_black);
  --bs-btn-hover-bg: var(--bysarago_color_yellow); // dark
  --bs-btn-hover-border-color: var(--bysarago_color_yellow); // dark
  --bs-btn-focus-gb: red;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--color_black);
  --bs-btn-active-bg: var(--bysarago_color_orange);
  --bs-btn-active-border-color: var(--bysarago_color_orange);
  --bs-btn-active-shadow: inset 0 3px 5pxrgba (0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--color_black);
  --bs-btn-disabled-bg: var(--color_light_grey);
  --bs-btn-disabled-border-color: var(--color_light_grey);

  box-shadow: var(--base-box-shadow);
  margin-top: 10px;
  width: 200px;
`;

export const CheckoutButtonLoadingSpinnerStyle: { [key: string]: string } = {
  width: '1rem',
  height: '1rem',
  '--bs-spinner-border-width': '0.1em',
};

export const NotValidShoppingCartPopOverStyle: { [key: string]: string } = {
  '--bs-popover-bg': 'var(--color_red_deny)',
  textAlign: 'center',
};
