import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { deviceMediaQueries } from '../Utils/ScreenSize';

export const HomeUltraWideImgWrapperStyledDiv = styled.div`
  overflow: hidden;
  position: relative;
  color: white;
`;

export const HomeUltraWideImgStyledImg = styled.img<{ maxHeight: string; width: string }>`
  max-height: ${p => p.maxHeight};
  object-fit: cover;
  pointer-events: none;
  width: ${p => p.width};
`;

export const HomeWideImgStyledImg = styled.img`
  pointer-events: none;

  @media only screen and ${deviceMediaQueries.mobile_vertical} {
    max-width: 100%;
  }

  @media only screen and ${deviceMediaQueries.mobile_horizontal} {
    max-width: 90%;
  }

  @media only screen and ${deviceMediaQueries.tablet_vertical} {
    max-width: 90%;
  }

  @media only screen and ${deviceMediaQueries.tablet_horizontal} {
    max-width: 70%;
  }

  @media only screen and ${deviceMediaQueries.laptop} {
    max-width: 70%;
  }
`;

export const HomeWrapperStyledDiv = styled.div``;

export const HomeSectionWrapperStyledDiv = styled.div`
  // Center in parents component: https://stackoverflow.com/a/1952287
  margin: 0px auto;
  max-height: 100%;
  max-width: 85%;
`;

export const HomeSectionPanelWrapperStyledDiv = styled.div<{ leftArea: string; rightArea: string }>`
  // Align text vertically
  align-items: center;
  display: grid;
  grid-gap: 2vh;
  justify-content: center;

  @media only screen and ${deviceMediaQueries.mobile_vertical} {
    grid-template-areas: '${p => p.leftArea}' '${p => p.rightArea}';
    grid-template-columns: 100%;
    grid-template-rows: fit-content fit-content;
  }

  @media only screen and ${deviceMediaQueries.mobile_horizontal} {
    grid-template-areas: '${p => p.leftArea}' '${p => p.rightArea}';
    grid-template-columns: 90%;
    grid-template-rows: fit-content fit-content;
  }

  @media only screen and ${deviceMediaQueries.tablet_vertical} {
    grid-template-areas: '${p => p.leftArea}' '${p => p.rightArea}';
    grid-template-columns: 90%;
    grid-template-rows: fit-content fit-content;
  }

  @media only screen and ${deviceMediaQueries.tablet_horizontal} {
    grid-template-areas: '${p => p.leftArea}' '${p => p.rightArea}';
    grid-template-columns: 70%;
    grid-template-rows: fit-content fit-content;
  }

  @media only screen and ${deviceMediaQueries.laptop} {
    grid-template-areas: '${p => p.leftArea} ${p => p.rightArea}';
    // calc(50% - 1vh) is to substract the 2vh addded in the grid
    grid-template-columns: min(400px, calc(50% - 1vh)) min(400px, calc(50% - 1vh));
    grid-template-rows: fit-content;
  }
`;

export const HomeSectionPanelImageStyledImg = styled.img<{ width?: string }>`
  grid-area: image;
  pointer-events: none;
  width: ${p => (p.width ? p.width : '100%')};
  display: -webkit-box;

  margin: 0px auto;
  max-height: 100%;
`;

export const HomeSectionPanelTextWrapperStyledDiv = styled.div`
  grid-area: text;
  width: 100%;
`;

export const HomeSectionWideTextStyledDiv = styled.div`
  // Center in parents component: https://stackoverflow.com/a/1952287
  margin: 0px auto;
  max-height: 100%;

  text-align: justify;
  text-justify: auto;

  @media only screen and ${deviceMediaQueries.mobile_vertical} {
    max-width: 100%;
  }

  @media only screen and ${deviceMediaQueries.mobile_horizontal} {
    max-width: 90%;
  }

  @media only screen and ${deviceMediaQueries.tablet_vertical} {
    max-width: 90%;
  }

  @media only screen and ${deviceMediaQueries.tablet_horizontal} {
    max-width: 70%;
  }

  @media only screen and ${deviceMediaQueries.laptop} {
    max-width: 70%;
  }
`;

export const HomeSectionTextStyledDiv = styled.div<{ fontSize?: string }>`
  color: var(--bysarago_color_purple_letters);
  font-size: ${p => (p.fontSize ? p.fontSize : '1rem')};
  font-weight: 300;
  text-align: justify;
  text-justify: auto;
`;

export const HomeNavigateToShopButtonStyledButton = styled(Button)`
  --bs-btn-color: var(--bysarago_color_purple_letters);
  --bs-btn-bg: var(--bysarago_color_yellow);
  --bs-btn-border-color: var(--bysarago_color_yellow);
  --bs-btn-hover-color: var(--bysarago_color_purple_letters);
  --bs-btn-hover-bg: var(--bysarago_color_yellow); // dark
  --bs-btn-hover-border-color: var(--bysarago_color_yellow); // dark
  --bs-btn-focus-gb: red;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--bysarago_color_purple_letters);
  --bs-btn-active-bg: var(--bysarago_color_purple_letters);
  --bs-btn-active-border-color: var(--bysarago_color_purple_letters);
  --bs-btn-active-shadow: inset 0 3px 5pxrgba (0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--bysarago_color_purple_letters);
  --bs-btn-disabled-bg: var(--color_light_grey);
  --bs-btn-disabled-border-color: var(--color_light_grey);

  box-shadow: var(--base-box-shadow);

  font-size: 1.2rem;
  height: 60px;
  width: 180px;
`;

export const HomeHeaderLogoOverlayStyledImg = styled.img`
  float: right;

  @media only screen and ${deviceMediaQueries.mobile_vertical} {
    width: 50%;
  }

  @media only screen and ${deviceMediaQueries.mobile_horizontal} {
    width: 50%;
  }

  @media only screen and ${deviceMediaQueries.tablet_vertical} {
    width: 70%;
  }

  @media only screen and ${deviceMediaQueries.tablet_horizontal} {
    width: 70%;
  }

  @media only screen and ${deviceMediaQueries.laptop} {
    width: 100%;
  }
`;
