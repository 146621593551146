import ga4 from 'react-ga4';

export const initGAnalytics = () => {
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID!;
  ga4.initialize(GA_MEASUREMENT_ID, {
    testMode: isDev,
  });
};

export const sendGAnalyticsEvent = (event_name: string, content: { [id: string]: string }) =>
  ga4.event(event_name, { app_name: 'BySárago', ...content });

export const sendGAnalyticsPageview = (path: string, title: string) =>
  ga4.send({ hitType: 'pageview', page: path, title });
