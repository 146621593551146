import { checkIfShoppingCartIsValid, getEarringsTotalPrice } from '../API/API';
import { EarringsTotalPrice, ShoppingCartValidity } from '../Models/API';
import { EarringMetalColor, EarringSelection } from '../Models/EarringDataModel';
import { GOLD_COLOR_HEX_CODE, LOCAL_STORAGE_KEY_SHOPPING_CART_CONTENT } from './Constants';

export function getShoppingCartContent(): EarringSelection[] | null {
  const shoppingCartContent = window.localStorage.getItem(LOCAL_STORAGE_KEY_SHOPPING_CART_CONTENT);
  if (shoppingCartContent) {
    const result: EarringSelection[] = JSON.parse(shoppingCartContent);
    return result;
  }

  return null;
}

export function saveShoppingCartContent(shoppingCartContent: EarringSelection[]) {
  window.localStorage.setItem(LOCAL_STORAGE_KEY_SHOPPING_CART_CONTENT, JSON.stringify(shoppingCartContent));
}

export function addItemToShoppingCartContent(earringSelection: EarringSelection) {
  const shoppingCartContent: EarringSelection[] | null = getShoppingCartContent();

  if (shoppingCartContent) {
    shoppingCartContent.push(earringSelection);
    saveShoppingCartContent(shoppingCartContent);
  } else {
    saveShoppingCartContent([earringSelection]);
  }
}

export function setShoppingCartItemMetalColorSelection(index: number, value: string) {
  const shoppingCartContent = getShoppingCartContent();
  if (shoppingCartContent && index <= shoppingCartContent.length) {
    shoppingCartContent[index].metalColor =
      value === GOLD_COLOR_HEX_CODE ? EarringMetalColor.Gold : EarringMetalColor.Silver;
    saveShoppingCartContent(shoppingCartContent);
  }

  return null;
}

export function getShoppingCartItemMetalColorSelection(index: number) {
  const shoppingCartContent = getShoppingCartContent();
  if (shoppingCartContent && index <= shoppingCartContent.length) {
    return shoppingCartContent[index].metalColor;
  }

  return null;
}

export function countEarringAndColorElementsInShoppingCart(earringID: string, color: string): number {
  const shoppingCartContent = window.localStorage.getItem(LOCAL_STORAGE_KEY_SHOPPING_CART_CONTENT);
  if (shoppingCartContent) {
    const result: EarringSelection[] = JSON.parse(shoppingCartContent);
    return result.filter(x => {
      return x.earringID === earringID && x.color === color;
    }).length;
  }

  return 0;
}

export function getIndexesEarringAndColorElementsInShoppingCart(earringID: string, color: string): number[] {
  const shoppingCartContent = window.localStorage.getItem(LOCAL_STORAGE_KEY_SHOPPING_CART_CONTENT);
  if (shoppingCartContent) {
    const result: EarringSelection[] = JSON.parse(shoppingCartContent);
    return result.map((x, i) => (x.earringID === earringID && x.color === color ? i : -1)).filter(i => i !== -1);
  }

  return [];
}

export async function computeTotalPrice(localShoppingCartContent: EarringSelection[]): Promise<EarringsTotalPrice> {
  if (localShoppingCartContent.length > 0) {
    // Get the earrings IDs of the earrings in the shopping cart
    const earringIDs: string[] = localShoppingCartContent.map(x => {
      return x.earringID;
    });
    // Get the earrings selected colors of the earrings in the shopping cart
    const earringColors: string[] = localShoppingCartContent.map(x => {
      return x.color;
    });
    // Hit the backend to get the total price for the earrings in the shopping cart
    return getEarringsTotalPrice({ earringIDs, earringColors });
  }

  // If no elements in the cart, the total price is 0!
  return new Promise<EarringsTotalPrice>(resolve => {
    resolve({
      total_price: 0.0,
      total_price_with_shipping: 0.0,
      all_included: true,
    } as EarringsTotalPrice);
  });
}

export async function isShoppingCartValid(localShoppingCartContent: EarringSelection[]): Promise<ShoppingCartValidity> {
  if (localShoppingCartContent.length > 0) {
    // Get the earrings IDs of the earrings in the shopping cart
    const earringIDs: string[] = localShoppingCartContent.map(x => {
      return x.earringID;
    });
    // Get the earrings selected colors of the earrings in the shopping cart
    const earringColors: string[] = localShoppingCartContent.map(x => {
      return x.color;
    });
    // Hit the backend to get the total price for the earrings in the shopping cart
    return checkIfShoppingCartIsValid({ earringIDs, earringColors });
  }

  // If no elements in the cart, the total price is 0!
  return new Promise<ShoppingCartValidity>(resolve => {
    resolve({
      is_valid: true,
    } as ShoppingCartValidity);
  });
}
