import styled from 'styled-components';
import { Navbar } from 'react-bootstrap';
import { deviceMediaQueries } from '../Utils/ScreenSize';

export const MainPageStyledDiv = styled.div`
  min-height: 100vh;
  position: relative;
`;

export const FooterStyledDiv = styled.div`
  align-items: top;
  background-color: var(--color_white);
  bottom: 0;
  color: var(--bysarago_color_purple_letters);
  display: flex;
  font-size: small;
  height: 35px;
  justify-content: center;
  margin-bottom: 1rem;
  position: relative;
  text-align: center;
  width: 100%;
`;

export const HeaderStyledNavbar = styled(Navbar)`
  background-color: var(--bysarago_color_purple_letters);
  position: sticky;
  top: 0;
  width: 100vw;
`;

export const RootPagesStyledDiv = styled.div<{ paddingBottom?: string; paddingTop?: string }>`
  min-height: 100%;
  padding-bottom: ${({ paddingBottom }) => paddingBottom || '2rem'};
  padding-top: ${({ paddingTop }) => paddingTop || '2vmin'};
  text-align: center;
`;

export const RootPagesStyledHeader2 = styled.h2`
  color: var(--bysarago_color_purple_letters);
`;

export const RootPagesStyledSpan = styled.span`
  color: var(--bysarago_color_purple_letters);
  display: inline-block;
  font-weight: 300;
  text-align: justify;
  text-justify: auto;

  @media only screen and ${deviceMediaQueries.mobile_vertical} {
    max-width: min(350px, 100%);
  }

  @media only screen and ${deviceMediaQueries.mobile_horizontal} {
    max-width: min(550px, 100%);
  }

  @media only screen and ${deviceMediaQueries.tablet_vertical} {
    max-width: min(550px, 90%);
  }

  @media only screen and ${deviceMediaQueries.tablet_horizontal} {
    max-width: 100%;
  }

  @media only screen and ${deviceMediaQueries.laptop} {
    max-width: min(750px, 100%);
  }
`;

interface SocialButtonProps {
  icon: string;
}

export const SocialButtonStyledButton = styled.button<SocialButtonProps>`
  background-size: contain;
  background: url(${p => p.icon});
  border: none;
  display: inline-block;
  height: 35px;
  margin: 0 5px 0 5px;
  width: 35px;
`;

export const SocialButtonsStyledDiv = styled.div`
  padding-top: 3vmin;
  text-align: center;
`;
