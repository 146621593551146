import { FooterStyledDiv } from '../Styles/GeneralStyles';

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <FooterStyledDiv>
      <span>
        &copy;&nbsp;{currentYear},&nbsp;BySárago&nbsp;-&nbsp;
        <a style={{ color: 'var(--bysarago_color_purple_letters)' }} aria-label="privacy" href="/privacy/">
          Privacidad
        </a>
        &nbsp;-&nbsp;
        <a style={{ color: 'var(--bysarago_color_purple_letters)' }} aria-label="terms" href="/terms/">
          Términos y condiciones
        </a>
      </span>
    </FooterStyledDiv>
  );
}

export { Footer };
