import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RootPagesStyledDiv, RootPagesStyledHeader2, RootPagesStyledSpan } from '../Styles/GeneralStyles';
import {
  LOCAL_STORAGE_KEY_EARRING_GRID_EARRING_CARD_CLICKED_POSITION,
  LOCAL_STORAGE_KEY_SHOPPING_CART_CONTENT,
} from '../Utils/Constants';

function CheckoutConfirmation() {
  useEffect(() => {
    document.title = 'BySárago - Pedido realizado';
  }, []);

  window.sessionStorage.removeItem(LOCAL_STORAGE_KEY_EARRING_GRID_EARRING_CARD_CLICKED_POSITION);

  const [searchParams, setSearchParams] = useSearchParams();
  const [paymentIntent, setPaymentIntent] = useState<string>();
  const [paymentIntentClientSecret, setPaymentIntentClientSecret] = useState<string>();
  const [redirectStatus, setRedirectStatus] = useState<string>();

  useEffect(() => {
    const paymentIntentParameter = searchParams.get('payment_intent');
    const paymentIntentClientSecretParameter = searchParams.get('payment_intent_client_secret');
    const redirectStatusParameter = searchParams.get('redirect_status');

    if (paymentIntentParameter) {
      setPaymentIntent(paymentIntentParameter);
      searchParams.delete('payment_intent');
    }
    if (paymentIntentClientSecretParameter) {
      setPaymentIntentClientSecret(paymentIntentClientSecretParameter);
      searchParams.delete('payment_intent_client_secret');
    }
    if (redirectStatusParameter) {
      setRedirectStatus(redirectStatusParameter);
      searchParams.delete('redirect_status');
    }

    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  if (paymentIntent && paymentIntentClientSecret && redirectStatus) {
    if (redirectStatus === 'succeeded') {
      window.localStorage.removeItem(LOCAL_STORAGE_KEY_SHOPPING_CART_CONTENT);
      // We need to manually fire an event so the NavBar refresh the shopping cart icon
      // https://stackoverflow.com/a/65348883
      window.dispatchEvent(new Event('storage'));
    }
  }

  return (
    <RootPagesStyledDiv>
      <RootPagesStyledHeader2>Confirmacion</RootPagesStyledHeader2>

      <RootPagesStyledSpan>¡Muchas gracias por tu pedido! 😍</RootPagesStyledSpan>
    </RootPagesStyledDiv>
  );
}

export { CheckoutConfirmation };
