import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { createPaymentIntent } from '../API/API';

import { RootPagesStyledDiv, RootPagesStyledHeader2 } from '../Styles/GeneralStyles';
import { LOCAL_STORAGE_KEY_EARRING_GRID_EARRING_CARD_CLICKED_POSITION } from '../Utils/Constants';
import CheckoutForm from './CheckoutForm';
import { CheckoutPageWrapperStyledDiv } from '../Styles/CheckoutPageStyles';
import { computeTotalPrice, getShoppingCartContent, isShoppingCartValid } from '../Utils/ShoppingCart';
import { EarringSelection } from '../Models/EarringDataModel';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_API_KEY!, { locale: 'es' });

function CheckoutPage() {
  useEffect(() => {
    document.title = 'BySárago - Realizar pedido';
  }, []);

  window.sessionStorage.removeItem(LOCAL_STORAGE_KEY_EARRING_GRID_EARRING_CARD_CLICKED_POSITION);

  const [clientSecret, setClientSecret] = useState('');
  const [paymentIntentId, setPaymentIntentId] = useState('');
  const [shoppingCartContent] = useState<EarringSelection[] | null>(getShoppingCartContent());
  const [shoppingCartTotalPrice, setShoppingCartTotalPrice] = useState<number>(0.0);

  useEffect(() => {
    if (shoppingCartContent) {
      computeTotalPrice(shoppingCartContent).then(x => {
        setShoppingCartTotalPrice(x.total_price_with_shipping);
      });

      isShoppingCartValid(shoppingCartContent).then(is_valid_info => {
        if (is_valid_info.is_valid) {
          const earringIDs: string[] = shoppingCartContent.map(x => {
            return x.earringID;
          });
          const earringColors: string[] = shoppingCartContent.map(x => {
            return x.color;
          });
          const earringMetalColors: string[] = shoppingCartContent.map(x => {
            return x.metalColor;
          });
          // Create PaymentIntent as soon as the page loads
          createPaymentIntent({ earringIDs, earringColors, earringMetalColors }).then(x => {
            setClientSecret(x.client_secret);
            setPaymentIntentId(x.id);
          });
        }
      });
    }
  }, [shoppingCartContent, shoppingCartTotalPrice]);

  return (
    <RootPagesStyledDiv>
      <RootPagesStyledHeader2>Checkout</RootPagesStyledHeader2>
      <CheckoutPageWrapperStyledDiv>
        {clientSecret && (
          <Elements
            options={{
              clientSecret,
              appearance: {
                theme: 'stripe',
                variables: {
                  colorPrimary: '#a7669e', // var(--bysarago_color_purple_letters)
                  colorBackground: '#ffffff', // var(--color_white)
                  colorText: '#3c2f31',
                },
              },
              locale: 'es',
            }}
            stripe={stripePromise}
            key={clientSecret}>
            <CheckoutForm totalPrice={shoppingCartTotalPrice} paymentIntentId={paymentIntentId} />
          </Elements>
        )}
      </CheckoutPageWrapperStyledDiv>
    </RootPagesStyledDiv>
  );
}

export { CheckoutPage };
