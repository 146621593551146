import { useState, useEffect, ReactNode, useRef, MutableRefObject } from 'react';
import { useNavigate } from 'react-router-dom';
import { EarringCard } from './EarringCard';
import { Earring } from '../Models/EarringDataModel';
import { LOCAL_STORAGE_KEY_EARRING_GRID_EARRING_CARD_CLICKED_POSITION } from '../Utils/Constants';
import { EarringGridStyledDiv } from '../Styles/EarringGridStyles';
import { getEarrings } from '../API/API';
import { LoadingSpinner } from './LoadingSpinner';

function EarringGrid(outlet: boolean = false): ReactNode {
  // Starting in React 18 (and in future versions), a component will be mounted and unmounted multiple
  // times during it's life. Every time a component is mounted, the useEffect (with [] as dependencis)
  // will be run. If we do not track if the useEffect has already been executed, it would query the
  // backend multiple times (every time the components get mounted). To avoid this, we introduce the
  // useRef function to generate a bool flag. Really good tutorial in this area:
  // https://www.youtube.com/watch?v=MXSuOR2yRvQ
  const shouldQueryEarrings: MutableRefObject<boolean> = useRef(true);
  const [earrings, setEarrings] = useState<Earring[]>();
  const navigate = useNavigate();

  useEffect(() => {
    if (shouldQueryEarrings.current) {
      const initEarrings = async () => {
        getEarrings(outlet).then(x => {
          setEarrings(x);
        });
      };
      shouldQueryEarrings.current = false;
      initEarrings();
    }
  }, [outlet]);

  useEffect(() => {
    const scrollPosition = window.sessionStorage.getItem(LOCAL_STORAGE_KEY_EARRING_GRID_EARRING_CARD_CLICKED_POSITION);
    if (scrollPosition && +scrollPosition > 20) {
      window.scrollTo(0, +scrollPosition);
    }
  }, [earrings]);

  const productCardOnClick = (earring: Earring) => {
    const { id: earringID } = earring;

    const productCardClicked: HTMLElement | null = document.getElementById(`EarringCard_${earringID}`);
    const mainDiv: HTMLElement | null = document.getElementById('RootPagesStyledDivHome');
    const productCardClickedPosition = productCardClicked!.offsetTop - mainDiv!.offsetTop;

    window.sessionStorage.setItem(
      LOCAL_STORAGE_KEY_EARRING_GRID_EARRING_CARD_CLICKED_POSITION,
      productCardClickedPosition.toString(),
    );

    navigate(`/earrings/${earringID}/`, { state: earring });
  };

  const productGridComponent = (() => {
    if (earrings) {
      return (
        <EarringGridStyledDiv>
          {earrings.map(earring => (
            <EarringCard key={earring.id} earring={earring} onClick={() => productCardOnClick(earring)} />
          ))}
        </EarringGridStyledDiv>
      );
    }
    return LoadingSpinner({ spinnerSize: '5em', margin: '20px' });
  })();

  return productGridComponent;
}

export { EarringGrid };
