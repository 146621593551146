import axios from 'axios';

const BASE_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

async function axiosFetch(params: {
  endpoint: string;
  method: string;
  headers?: { [key: string]: string };
  withCredentials?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- We want this function to be generic
  data?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- We want this function to be generic
}): Promise<any> {
  const { endpoint, method, headers, withCredentials, data } = params;

  if (!endpoint) throw new Error('endpoint is missing.');
  if (!method) throw new Error('method is missing.');
  if (method === 'POST' && !data) throw new Error('method is set to POST and data is missing.');

  const axiosConfig = {
    url: endpoint,
    method,
    withCredentials,
    header: headers || BASE_HEADERS,
    data,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- We want this function to be generic
  const result: any = await axios<any>(axiosConfig).then(resp => {
    return resp.data;
  });

  return result;
}

export { axiosFetch };
