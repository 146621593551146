import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendGAnalyticsEvent } from '../GAnalytics/Main';
import {
  RootPagesStyledDiv,
  RootPagesStyledHeader2,
  SocialButtonStyledButton,
  SocialButtonsStyledDiv,
} from '../Styles/GeneralStyles';
import {
  HomeHeaderLogoOverlayStyledImg,
  HomeNavigateToShopButtonStyledButton,
  HomeSectionPanelImageStyledImg,
  HomeSectionPanelTextWrapperStyledDiv,
  HomeSectionPanelWrapperStyledDiv,
  HomeSectionTextStyledDiv,
  HomeSectionWideTextStyledDiv,
  HomeSectionWrapperStyledDiv,
  HomeUltraWideImgStyledImg,
  HomeUltraWideImgWrapperStyledDiv,
  HomeWideImgStyledImg,
  HomeWrapperStyledDiv,
} from '../Styles/HomeStyles';
import {
  FREE_IF_GREATER_THAN,
  LOCAL_STORAGE_KEY_EARRING_GRID_EARRING_CARD_CLICKED_POSITION,
  SHIPPING_COSTS,
} from '../Utils/Constants';

import EmailIcon from '../Resources/SocialIcons/email.svg';
import InstagramIcon from '../Resources/SocialIcons/instagram.svg';
import TikTokIcon from '../Resources/SocialIcons/tiktok.svg';

import bySaragoLogo from '../Resources/bysarago_logo_80x80.webp';

import me from '../Resources/HomeImages/Me/me.webp';
import me_256w from '../Resources/HomeImages/Me/me_256w.webp';
import me_320w from '../Resources/HomeImages/Me/me_320w.webp';
import me_384w from '../Resources/HomeImages/Me/me_384w.webp';
import me_430w from '../Resources/HomeImages/Me/me_430w.webp';
import me_500w from '../Resources/HomeImages/Me/me_500w.webp';
import me_640w from '../Resources/HomeImages/Me/me_640w.webp';
import me_750w from '../Resources/HomeImages/Me/me_750w.webp';
import me_828w from '../Resources/HomeImages/Me/me_828w.webp';
import me_1080w from '../Resources/HomeImages/Me/me_1080w.webp';
import me_1200w from '../Resources/HomeImages/Me/me_1200w.webp';
import me_1920w from '../Resources/HomeImages/Me/me_1920w.webp';
import me_2048w from '../Resources/HomeImages/Me/me_2048w.webp';
import me_3840w from '../Resources/HomeImages/Me/me_3840w.webp';

import overview from '../Resources/HomeImages/Overview/overview.webp';
import overview_256w from '../Resources/HomeImages/Overview/overview_256w.webp';
import overview_320w from '../Resources/HomeImages/Overview/overview_320w.webp';
import overview_384w from '../Resources/HomeImages/Overview/overview_384w.webp';
import overview_430w from '../Resources/HomeImages/Overview/overview_430w.webp';
import overview_500w from '../Resources/HomeImages/Overview/overview_500w.webp';
import overview_640w from '../Resources/HomeImages/Overview/overview_640w.webp';
import overview_750w from '../Resources/HomeImages/Overview/overview_750w.webp';
import overview_828w from '../Resources/HomeImages/Overview/overview_828w.webp';
import overview_1080w from '../Resources/HomeImages/Overview/overview_1080w.webp';
import overview_1200w from '../Resources/HomeImages/Overview/overview_1200w.webp';
import overview_1920w from '../Resources/HomeImages/Overview/overview_1920w.webp';
import overview_2048w from '../Resources/HomeImages/Overview/overview_2048w.webp';
import overview_3840w from '../Resources/HomeImages/Overview/overview_3840w.webp';

import header from '../Resources/HomeImages/Header/header.webp';
import header_256w from '../Resources/HomeImages/Header/header_256w.webp';
import header_320w from '../Resources/HomeImages/Header/header_320w.webp';
import header_384w from '../Resources/HomeImages/Header/header_384w.webp';
import header_430w from '../Resources/HomeImages/Header/header_430w.webp';
import header_500w from '../Resources/HomeImages/Header/header_500w.webp';
import header_640w from '../Resources/HomeImages/Header/header_640w.webp';
import header_750w from '../Resources/HomeImages/Header/header_750w.webp';
import header_828w from '../Resources/HomeImages/Header/header_828w.webp';
import header_1080w from '../Resources/HomeImages/Header/header_1080w.webp';
import header_1200w from '../Resources/HomeImages/Header/header_1200w.webp';
import header_1920w from '../Resources/HomeImages/Header/header_1920w.webp';
import header_2048w from '../Resources/HomeImages/Header/header_2048w.webp';
import header_3840w from '../Resources/HomeImages/Header/header_3840w.webp';

function buildSocialButtons() {
  return (
    <SocialButtonsStyledDiv>
      <a
        onClick={() => sendGAnalyticsEvent('social_media', { network: 'Instagram' })}
        href="https://www.instagram.com/bysarago/"
        aria-label="Instagram"
        target="_blank"
        rel="noreferrer">
        <SocialButtonStyledButton icon={InstagramIcon} type="button" aria-label="Instagram" />
      </a>

      <a
        onClick={() => sendGAnalyticsEvent('social_media', { network: 'Tiktok' })}
        href="https://www.tiktok.com/@bysarago"
        aria-label="TikTok"
        target="_blank"
        rel="noreferrer">
        <SocialButtonStyledButton icon={TikTokIcon} type="button" aria-label="TikTok" />
      </a>

      <a
        onClick={() => sendGAnalyticsEvent('social_media', { network: 'Email' })}
        href="mailto:bysarago@gmail.com"
        aria-label="Email"
        target="_blank"
        rel="noreferrer">
        <SocialButtonStyledButton icon={EmailIcon} type="button" aria-label="Email" />
      </a>
    </SocialButtonsStyledDiv>
  );
}

function Home() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'BySárago - Inicio';
  }, []);

  window.sessionStorage.removeItem(LOCAL_STORAGE_KEY_EARRING_GRID_EARRING_CARD_CLICKED_POSITION);
  return (
    <RootPagesStyledDiv paddingBottom="3vh" paddingTop="0px">
      <HomeWrapperStyledDiv>
        <HomeUltraWideImgWrapperStyledDiv>
          <HomeUltraWideImgStyledImg
            maxHeight="80vmin"
            width="100%"
            src={header}
            srcSet={`
              ${header_256w} 256w, ${header_320w} 320w, ${header_384w} 384w, ${header_430w} 430w,
              ${header_500w} 500w, ${header_640w} 640w, ${header_750w} 750w, ${header_828w} 828w,
              ${header_1080w} 1080w, ${header_1200w} 1200w, ${header_1920w} 1920w,
              ${header_2048w} 2048w, ${header_3840w} 3840
            `}
            alt="home header"
          />
          <div style={{ position: 'absolute', bottom: '1rem', left: '1rem', color: 'white', fontSize: '1.5rem' }}>
            BySárago
          </div>
          <div style={{ position: 'absolute', bottom: '1rem', right: '1rem' }}>
            <HomeHeaderLogoOverlayStyledImg src={bySaragoLogo} alt="bysarago logo" />
          </div>
        </HomeUltraWideImgWrapperStyledDiv>

        <HomeSectionWrapperStyledDiv>
          <RootPagesStyledHeader2 style={{ marginBottom: '1rem', marginTop: '2rem' }}>Tienda</RootPagesStyledHeader2>

          <HomeNavigateToShopButtonStyledButton
            onClick={() => {
              navigate(`/shop/`);
            }}>
            Pendientes&nbsp;
            <i className="bi bi-arrow-right" style={{ fontSize: '1rem' }} />
          </HomeNavigateToShopButtonStyledButton>
        </HomeSectionWrapperStyledDiv>

        <HomeSectionWrapperStyledDiv>
          <RootPagesStyledHeader2 style={{ marginBottom: '1rem', marginTop: '2rem' }} />

          <HomeNavigateToShopButtonStyledButton
            onClick={() => {
              navigate(`/outlet/`);
            }}>
            Outlet&nbsp;
            <i className="bi bi-arrow-right" style={{ fontSize: '1rem' }} />
          </HomeNavigateToShopButtonStyledButton>
        </HomeSectionWrapperStyledDiv>

        <HomeSectionWrapperStyledDiv>
          <RootPagesStyledHeader2 style={{ marginBottom: '1rem', marginTop: '2rem' }}>
            ¿Qué es BySárago?
          </RootPagesStyledHeader2>
          <HomeSectionPanelWrapperStyledDiv leftArea="image" rightArea="text">
            <HomeSectionPanelImageStyledImg
              src={me}
              srcSet={`
                ${me_256w} 256w, ${me_320w} 320w, ${me_384w} 384w, ${me_430w} 430w, ${me_500w} 500w, ${me_640w} 640w,
                ${me_750w} 750w, ${me_828w} 828w, ${me_1080w} 1080w, ${me_1200w} 1200w, ${me_1920w} 1920w,
                ${me_2048w} 2048w, ${me_3840w} 3840w
              `}
              width="80%"
              alt="me"
            />
            <HomeSectionPanelTextWrapperStyledDiv>
              <HomeSectionTextStyledDiv fontSize="1.125rem">
                Hola! Mi nombre es Saray, una canaria 🇮🇨 viviendo en Granada. En primer lugar me gustaría darte las
                gracias por visitar esta web y de algún modo apoyar la artesanía. 😊
                <br />
                <br />
                Soy una persona creativa, y me gusta probar nuevas formas de expresarlo, mi otro gran hobbie es el
                crochet 🧶. Como habrás podido comprobar me encantan las flores, son mi mayor inspiración y en muchos de
                mis modelos las incluyo. Con BySárago quiero hacerles llegar complementos con carácter, originales y lo
                más bonitos posible. 🎨
              </HomeSectionTextStyledDiv>
            </HomeSectionPanelTextWrapperStyledDiv>
          </HomeSectionPanelWrapperStyledDiv>
        </HomeSectionWrapperStyledDiv>

        <HomeSectionWrapperStyledDiv>
          <RootPagesStyledHeader2 style={{ marginBottom: '1rem', marginTop: '2rem' }}>
            Materiales y cuidados
          </RootPagesStyledHeader2>

          <HomeSectionWideTextStyledDiv>
            <HomeSectionTextStyledDiv fontSize="1.125rem">
              La arcilla polimérica es la base de todos los pendientes, es un material que se endurece al horno, súper
              versátil; ¡ofrece un sin fin de posibilidades! Además, es muy ligero por lo que no pesan y son cómodos de
              llevar 🪶. Los enganches son siempre de acero inoxidable, así evitamos alergias o molestias. Algunos de
              los modelos tienen un acabado en resina UV o barniz para darle un toque de brillo. ✨
              <br />
              <br />
              La arcilla polimérica es un material flexible, resistente y duradero pero hay una serie de consejos que
              puedes seguir para evitar que se deterioren:
              <br /> &#x2022; Evita exponerlos directamente al sol y bañarte con ellos.
              <br /> &#x2022; Evita dormir con ellos ya que pueden deformarse.
              <br /> &#x2022; Cuando no los estés usando, guárdalos en un lugar seguro para evitar que sufran roturas.
            </HomeSectionTextStyledDiv>
          </HomeSectionWideTextStyledDiv>
        </HomeSectionWrapperStyledDiv>

        <HomeSectionWrapperStyledDiv style={{ marginBottom: '1rem', marginTop: '2rem' }}>
          <HomeWideImgStyledImg
            src={overview}
            srcSet={`
              ${overview_256w} 256w, ${overview_320w} 320w, ${overview_384w} 384w, ${overview_430w} 430w,
              ${overview_500w} 500w, ${overview_640w} 640w, ${overview_750w} 750w, ${overview_828w} 828w,
              ${overview_1080w} 1080w, ${overview_1200w} 1200w, ${overview_1920w} 1920w,
              ${overview_2048w} 2048w, ${overview_3840w} 3840w
            `}
            alt="overview"
          />
        </HomeSectionWrapperStyledDiv>

        <HomeSectionWrapperStyledDiv>
          <RootPagesStyledHeader2 style={{ marginBottom: '1rem', marginTop: '2rem' }}>
            Información de envío
          </RootPagesStyledHeader2>

          <HomeSectionWideTextStyledDiv>
            <HomeSectionTextStyledDiv fontSize="1.125rem">
              Es importante tener en cuenta que la preparación, empaquetado y envío se realizará con la mayor brevedad
              posible, pero puede demorar en unos 2-3 días el plazo de entrega. Una vez el pedido esté listo, será
              enviado a través de Correos de forma certificada (con número de seguimiento del pedido, para que estés al
              tanto en todo momento). El precio del envío es de {SHIPPING_COSTS}€. A partir de {FREE_IF_GREATER_THAN}€
              el envío será gratuito. Una vez tu pedido sea entregado en Correos tardarás entre 3-5 días hábiles en
              recibirlo. Se hacen envíos a toda España (incluido Canarias, Baleares, Ceuta y Melilla).
              <br />
              <br />
              Si vives en Granada (ciudad), existe la posibilidad de entregar el paquete en mano de forma gratuita.
              ¡Ponte en contacto conmigo si es el caso!
            </HomeSectionTextStyledDiv>
          </HomeSectionWideTextStyledDiv>
        </HomeSectionWrapperStyledDiv>

        <HomeSectionWrapperStyledDiv>
          <RootPagesStyledHeader2 style={{ marginBottom: '1rem', marginTop: '2rem' }}>Contacto</RootPagesStyledHeader2>

          <HomeSectionWideTextStyledDiv>
            <HomeSectionTextStyledDiv fontSize="1.125rem">
              Si tienes cualquier duda, pregunta o sugerencia, no dudes en ponerte en contacto a través de correo
              electrónico (
              <a
                style={{ color: 'var(--bysarago_color_purple_letters)' }}
                href="mailto:bysarago@gmail.com"
                aria-label="Email"
                target="_blank"
                rel="noreferrer">
                bysarago@gmail.com
              </a>
              ), Instagram o TikTok.
              {buildSocialButtons()}
            </HomeSectionTextStyledDiv>
          </HomeSectionWideTextStyledDiv>
        </HomeSectionWrapperStyledDiv>
      </HomeWrapperStyledDiv>
    </RootPagesStyledDiv>
  );
}

export { Home };
