import { MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { ColorResult } from 'react-color';
import { AxiosError } from 'axios';
import { Earring, EarringMetalColor, EarringSelection } from '../Models/EarringDataModel';
import {
  ShoppingCartItemImageStyledDiv,
  ShoppingCartItemColorInfoStyledSpan,
  ShoppingCartItemMetalColorInfoStyledSpan,
  ShoppingCartItemNameStyledH3,
  ShoppingCartItemWrapperStyledDiv,
  ShoppingCartItemPriceInfoStyledSpan,
  ShoppingCartItemRemoveItemStyledSpan,
  RemoveShoppingCartItemButtonStyle,
} from '../Styles/ShoppingCartItemStyles';
import { GOLD_COLOR_HEX_CODE, IMAGES_EARRINGS_PATH, SILVER_COLOR_HEX_CODE } from '../Utils/Constants';
import { getEarring } from '../API/API';
import {
  getIndexesEarringAndColorElementsInShoppingCart,
  getShoppingCartContent,
  getShoppingCartItemMetalColorSelection,
  setShoppingCartItemMetalColorSelection,
} from '../Utils/ShoppingCart';
import { ColorPicker } from './ColorPicker';
import { LoadingSpinner } from './LoadingSpinner';

interface ShoppingCartItemProps {
  positionInShoppingCart: number;
  earringSelection: EarringSelection;
  deleteItemOnClick: () => void;
  updateCartOnClick: (x: EarringSelection[]) => void;
}

function buildEarringMetalColorInfo(
  earringItem: Earring,
  positionInShoppingCart: number,
  selectedColor: string,
  setSelectedMetalColorState: React.Dispatch<React.SetStateAction<string>>,
  updateCartOnClick: (x: EarringSelection[]) => void,
  inStock: boolean,
): ReactNode {
  let metalColorInfo: ReactNode;

  if (earringItem.metalColor === EarringMetalColor.Silver) {
    metalColorInfo = <span>Enganches plateados</span>;
  } else if (earringItem.metalColor === EarringMetalColor.Gold) {
    metalColorInfo = <span>Enganches dorados</span>;
  } else if (earringItem.metalColor === EarringMetalColor.NotVisible) {
    metalColorInfo = <span>Enganches no visibles</span>;
  } else {
    const metalColorSelectionFromStorage = getShoppingCartItemMetalColorSelection(positionInShoppingCart);
    metalColorInfo = (
      <>
        <span>Enganches:&nbsp;</span>
        <ColorPicker
          withHash
          color={metalColorSelectionFromStorage || selectedColor}
          colors={[GOLD_COLOR_HEX_CODE, SILVER_COLOR_HEX_CODE]}
          onChange={(color: ColorResult) => {
            const localShoppingCartContent = getShoppingCartContent();
            if (localShoppingCartContent) {
              localShoppingCartContent[positionInShoppingCart].metalColor =
                color.hex === GOLD_COLOR_HEX_CODE ? EarringMetalColor.Gold : EarringMetalColor.Silver;
              updateCartOnClick(localShoppingCartContent);
            }
            setShoppingCartItemMetalColorSelection(positionInShoppingCart, color.hex);
            setSelectedMetalColorState(color.hex);
          }}
          circleSize={14}
          circleSpacing={3}
        />
      </>
    );
  }

  return (
    <ShoppingCartItemMetalColorInfoStyledSpan inStock={inStock}>
      {metalColorInfo}
    </ShoppingCartItemMetalColorInfoStyledSpan>
  );
}

function buildEarringColorInfo(earringSelection: EarringSelection, earring: Earring, inStock: boolean): ReactNode {
  return (
    <ShoppingCartItemColorInfoStyledSpan inStock={inStock}>
      Color:&nbsp;{earring.colorHexToString[earringSelection.color]}&nbsp;
    </ShoppingCartItemColorInfoStyledSpan>
  );
}

function buildPriceInformation(price: number, discountedPrice: number | null, inStock: boolean): ReactNode {
  let priceInfo: ReactNode;

  if (inStock) {
    if (!discountedPrice) {
      priceInfo = <span>{price}€</span>;
    } else {
      priceInfo = (
        <span>
          <s
            style={{
              textDecorationThickness: 'var(--text_decoration_thickness)',
              textDecorationColor: 'var(--color_charcoal)',
            }}>
            {price}€
          </s>
          &nbsp;&nbsp;{discountedPrice}€
        </span>
      );
    }
  } else {
    priceInfo = <span style={{ color: 'var(--color_black)' }}>Agotado</span>;
  }

  return priceInfo;
}

function ShoppingCartItem(props: ShoppingCartItemProps) {
  const { earringSelection, positionInShoppingCart, deleteItemOnClick, updateCartOnClick } = props;
  const shouldQueryEarring: MutableRefObject<boolean> = useRef(true);
  const [earringItem, setEarringItem] = useState<Earring>();
  const navigate: NavigateFunction = useNavigate();

  const [selectedMetalColorState, setSelectedMetalColorState] = useState<string>(earringSelection.metalColor);
  const [metalColorSelectorReactNode, setMetalColorSelectorReactNode] = useState<ReactNode>();

  const productCardOnClick = (item: EarringSelection) => {
    navigate(`/earrings/${item.earringID}/`);
  };

  useEffect(() => {
    if (shouldQueryEarring.current) {
      const initEarring = async () => {
        getEarring({ earringID: earringSelection.earringID })
          .then(x => {
            setEarringItem(x);
          })
          // eslint-disable-next-line @typescript-eslint/no-unused-vars -- We do not need to do anything with the error
          .catch((_error: AxiosError) => {
            deleteItemOnClick();
          });
      };
      shouldQueryEarring.current = false;
      initEarring();
    }
  }, [earringSelection.earringID, deleteItemOnClick]);

  useEffect(() => {
    if (earringItem && selectedMetalColorState) {
      const indexesInShoppingCart = getIndexesEarringAndColorElementsInShoppingCart(
        earringItem.id,
        earringSelection.color,
      );
      const stockInDB = earringItem.colorsAndStock[earringSelection.color];
      const stockInPosition = indexesInShoppingCart.indexOf(positionInShoppingCart) < stockInDB;
      const inStock: boolean = stockInPosition && stockInDB > 0;

      setMetalColorSelectorReactNode(
        buildEarringMetalColorInfo(
          earringItem,
          positionInShoppingCart,
          selectedMetalColorState,
          setSelectedMetalColorState,
          updateCartOnClick,
          inStock,
        ),
      );
    }
  }, [earringItem, positionInShoppingCart, selectedMetalColorState, updateCartOnClick, earringSelection.color]);

  // eslint-disable-next-line max-len -- We explicitly need this
  const mainImageUrl: string = `${IMAGES_EARRINGS_PATH}/${earringSelection.earringID}/${earringSelection.color}/1.webp`;

  if (earringItem) {
    const indexesInShoppingCart = getIndexesEarringAndColorElementsInShoppingCart(
      earringItem.id,
      earringSelection.color,
    );
    const stockInDB = earringItem.colorsAndStock[earringSelection.color];
    const stockInPosition = indexesInShoppingCart.indexOf(positionInShoppingCart) < stockInDB;
    const inStock: boolean = stockInPosition && stockInDB > 0;

    const earringColorInfo: ReactNode = buildEarringColorInfo(earringSelection, earringItem, inStock);
    const htmlID = [
      'ShoppingCartItemWrapperStyledDiv',
      earringItem.id,
      earringSelection.color,
      positionInShoppingCart,
      new Date().getTime(), // new Date().getTime() is needed to re-create the metal color selector
    ].join('_');

    return (
      <ShoppingCartItemWrapperStyledDiv id={htmlID} key={htmlID} inStock={inStock}>
        <ShoppingCartItemImageStyledDiv
          onClick={() => productCardOnClick(earringSelection)}
          background={mainImageUrl}
          inStock={inStock}
        />
        <ShoppingCartItemNameStyledH3 inStock={inStock} onClick={() => productCardOnClick(earringSelection)}>
          {earringItem.name}
        </ShoppingCartItemNameStyledH3>
        {earringColorInfo}
        {metalColorSelectorReactNode}
        <ShoppingCartItemRemoveItemStyledSpan>
          <button type="button" className="btn" style={RemoveShoppingCartItemButtonStyle} onClick={deleteItemOnClick}>
            <i className="bi bi-trash" />
          </button>
        </ShoppingCartItemRemoveItemStyledSpan>
        <ShoppingCartItemPriceInfoStyledSpan>
          {buildPriceInformation(earringItem.price, earringItem.discountedPrice, inStock)}
        </ShoppingCartItemPriceInfoStyledSpan>
      </ShoppingCartItemWrapperStyledDiv>
    );
  }

  return LoadingSpinner({ spinnerSize: '5em', margin: '0px' });
}

export { ShoppingCartItem };
